import { FileChecksum } from '@rails/activestorage/src/file_checksum.js'

angular
  .module('atelier.uploaders')
  .factory('ActiveStorage', ActiveStorage)

ActiveStorage['$inject'] = ['$q', '$http']
function ActiveStorage ($q, $http) {
  return {
    sign: sign
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function sign (file) {
    const deferred = $q.defer()

    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        deferred.reject(error)
        return
      }

      $http.post('/rails/active_storage/direct_uploads', {
        blob: {
          filename:     file.name,
          content_type: file.type || 'application/octet-stream',
          byte_size:    file.size,
          checksum:     checksum
        }
      }).then(response => deferred.resolve(response))
    })

    return deferred.promise
  }
}
