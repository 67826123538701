angular
  .module('atelier.loadingButtons')
  .component('buttonLoading', {
    transclude: true,
    template:
      '<md-progress-circular md-diameter="16" ng-if="$ctrl.loading"></md-progress-circular>' +
      '<ng-transclude></ng-transclude>',
    bindings: {
      loading: '<'
    }
  })
