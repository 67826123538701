angular
  .module('atelier.locationURL', [])
  .factory('LocationURL', LocationURLFactory)

LocationURLFactory['$inject'] = ['$httpParamSerializerJQLike']
function LocationURLFactory ($httpParamSerializerJQLike) {
  return class LocationURL {
    constructor (value) {
      this.parse(value)
    }

    set url (value) {
      this.parse(value)
    }

    get url () {
      return toString()
    }

    appendPath (path) {
      this.path = (this.path + '/' + path).replace(/\/{2,}/, '/')
      return this
    }

    mergeQuery (value) {
      this.query = angular.extend({}, this.query, value)
      return this
    }

    reverseMergeQuery (value) {
      this.query = angular.extend({}, value, this.query)
      return this
    }

    search (key, value) {
      if (angular.isUndefined(value)) return this.query[key]

      this.query[key] = value
      return this
    }

    parse (value) {
      const match = value.match(/^(#!)?([^?#]+)(\?([^#]+))?(#(.+))?$/)
      if (!match) throw new Error('Invalid location URL: ' + value)

      this.path  = match[2]
      this.query = LocationURL.parseQuery(match[4])
      this.hash  = match[6]

      return this
    }

    toString () {
      let url = this.path

      if (Object.keys(this.query).length) url += ('?' + $httpParamSerializerJQLike(this.query))
      if (this.hash && this.hash.length) url += ('#' + this.hash)

      return url
    }

    static parseQuery (query) {
      if (!query) return {}

      const parts     = query.split('&')
      const queryHash = {}

      parts.forEach(function (part) {
        const pair  = part.split('=')
        const key   = decodeURIComponent(pair[0])
        const value = decodeURIComponent(pair[1])
        queryHash[key] = value
      })

      return queryHash
    }
  }
}
