angular
  .module('atelier.editable')
  .directive('editableCard', function () {
    return {
      restrict:   'A',
      controller: EditableCardController
    }
  })

EditableCardController['$inject'] = ['$element']
function EditableCardController ($element) {
  const ctrl  = this

  ctrl.focus = focus
  ctrl.blur  = blur

  $element
    .attr('tabindex', '0')
    .attr('role', 'button')
    .attr('aria-expanded', 'false')
    .on('click', click)

  // Functions
  // --------------------------------------------------------------------------------
  function click () {
    if ($element.hasClass('editable-focused')) return

    $element.find('[editable-card-input]').focus()
  }

  function focus () {
    $element
      .addClass('editable-focused')
      .attr('aria-expanded', 'true')
  }

  function blur () {
    $element
      .removeClass('editable-focused')
      .attr('aria-expanded', 'false')
  }
}
