// This factory assists yout to submit forms.
//
//    new RailsResourceForm({ formController: ctrl.form, resourceUrl: '/my/resources', name: 'resource' })
//    new RailsResourceForm({ formController: ctrl.form, resource: resource })
//    new RailsResourceForm({ formController: ctrl.form, resource: Resource })
//
// You can submit with or without params, depending on you alreay passed the resource in the constructor :
//
//    new RailsResourceForm({ formController: ctrl.form, resource: resource }).submit()
//    new RailsResourceForm({ formController: ctrl.form, resource: ResourceMode }).submit(resource)
//
angular
  .module('atelier.railsResourceForm')
  .service('RailsResourceForm', RailsResourceFormFactory)

RailsResourceFormFactory['$inject'] = ['railsResourceFactory', '$log', '$q']
function RailsResourceFormFactory (railsResourceFactory, $log, $q) {
  return class RailsResourceForm {
    constructor (config) {
      if (!config) $log.error('No config provided to instantiate RailsResourceForm')

      if (config.resource && angular.isFunction(config.resource.save)) {
        this.resource = config.resource
      } else if (config.resource && config.resource.name === 'Resource') {
        this.model = config.resource
      } else {
        if (!config.resourceUrl) $log.error('No `resourceUrl` provided to instantiate RailsResourceForm')
        if (!config.resourceName) $log.error('No `resourceName` provided to instantiate RailsResourceForm')

        this.model = railsResourceFactory({
          url:          config.resourceUrl,
          name:         config.resourceName,
          singular:     config.singular,
          updateMethod: config.updateMethod
        })

        if (config.resource) this.build(config.resource)
      }

      if (config.formController) this.formController = config.formController
    }

    // Public function
    // -----------------------------------------------------------------------
    build (resource) {
      const Model = this.model
      this.resource = new Model(resource)
    }

    submit (data) {
      const Model  = this.model
      let resource = this.resource

      if (data && resource) {
        $log.debug('Resource and data have been passed to be submitted through RailsResourceForm. They will be merged before submitting.')
        resource = angular.extend(resource, data)
      } else if (data) {
        resource = new Model(data)
      } else if (!resource) {
        $log.error('No `resource` provided to submit RailsResourceForm instance')
        return $q.reject()
      }

      return resource.save().catch((response) => {
        const form = this.formController

        if (form) {
          RailsResourceForm.applyErrors(form, response.data.errors)
          form.$submitted = false
        }

        return $q.reject(response)
      })
    }

    // Statuc function
    // -----------------------------------------------------------------------
    static applyErrors (formController, errors) {
      angular.forEach(formController.$$controls, (control) => {
        Object.keys(control.$error).forEach((key) => {
          control.$setValidity(key, true)
        })
      })

      angular.forEach(errors, (attributeErors, attribute) => {
        let control = formController[attribute]

        if (angular.isUndefined(control)) {
          control = {
            $name:            attribute,
            $invalid:         false,
            $error:           {},
            $commitViewValue: angular.noop,
            $setTouched:      angular.noop,
            $setValidity:     function (name, value) {
              this.$error[name] = !value
              this.$invalid     = !!Object.keys(this.$error).length
            }
          }

          formController.$addControl(control)
        }

        angular.forEach(attributeErors, (error) => {
          control.$setTouched()
          control.$setValidity(error.error, false)
          control.$error[error.error] = error
        })
      })
    }
  }
}
