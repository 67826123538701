angular
  .module('atelier.autocomplete')
  .filter('autocomplete', autocomplete)

autocomplete['$inject'] = ['$filter', 'removeDiacritics']
function autocomplete ($filter, removeDiacritics) {
  return function (input, expression) {
    return $filter('filter')(input, expression, comparator)
  }

  function comparator (actual, query) {
    if (angular.isString(actual) && angular.isString(query)) {
      query = transliterate(query)
      actual = transliterate(actual)

      return actual.indexOf(query) > -1
    } else {
      return angular.equals(actual, query)
    }
  }

  function transliterate (value) {
    return removeDiacritics.replace(value.toLowerCase().replace('-', ' '))
  }
}
