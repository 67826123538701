/**
 * Opens googleMaps' streetview and handles navigation
 */
angular
  .module('atelier.GIS')
  .component('streetViewPanorama', {
    controller: Controller,
    require:    {
      streetViewCtrl: '^streetView'
    },
    bindings: {
      hideUi: '<'
    }
  })

Controller['$inject'] = ['$element', '$timeout', '$scope', 'handleGesture']
function Controller ($element, $timeout, $scope, handleGesture) {
  const ctrl = this

  // Private variables
  // -------------------------------------------------------------------------
  let googleMaps
  let service
  let options
  let panorama

  // Bindable members
  // -------------------------------------------------------------------------
  ctrl.$onInit    = onInit
  ctrl.$onChanges = onChanges
  ctrl.$onDestroy = onDestroy

  // Component hooks
  // -------------------------------------------------------------------------
  function onInit () {
    googleMaps = ctrl.streetViewCtrl.googleMaps
    service    = ctrl.streetViewCtrl.service
    options    = service.options.streetView

    // Wait a bit before drawing Panorama
    // Let some time to the browser to properly render the DOM
    $timeout(function () {
      panorama = new googleMaps.StreetViewPanorama($element[0], options)
      service.set('streetViewPanorama', panorama)

      googleMaps.event.addListener(panorama, 'closeclick', close)
      googleMaps.event.addListener(panorama, 'visible_changed', resize)
      googleMaps.event.addListener(panorama, 'position_changed', positionChanged)
      googleMaps.event.addListener(panorama, 'pov_changed', povChanged)

      service.set('streetViewPanorama', panorama)
      service.on('streetViewUpdated', moveTo)
      service.on('redraw', redraw)

      if (service.options.handleGesture) {
        handleGesture.add($element, enableScrollWheel, disableScrollWheel)
      }

      redraw()
      moveTo(service.get('streetView'))
    }, 500)

    $scope.$watch(getElementSize, resize)
  }

  function onChanges () {
    if (panorama) {
      const newOptions = {
        disableDefaultUI: ctrl.hideUi
      }

      // Do not overide default options when it comes back
      if (!ctrl.hideUi) angular.extend(newOptions, options)

      panorama.setOptions(newOptions)
    }
  }

  function onDestroy () {
    if (panorama && googleMaps) googleMaps.event.clearInstanceListeners(panorama)

    service.set('streetViewPanorama', null)
    service.off('streetViewUpdated', moveTo)
    service.off('redraw', redraw)

    handleGesture.remove($element)

    $element.unbind()
  }

  // Private function
  // -------------------------------------------------------------------------
  function disableScrollWheel () {
    panorama.setOptions({ scrollwheel: false })
  }

  function enableScrollWheel () {
    panorama.setOptions({ scrollwheel: true })
  }

  function close () {
    service.set('streetViewVisible', false)
    service.redraw()
  }

  function moveTo (view) {
    if (view && view.position && view.pov) {
      ctrl.streetViewCtrl.placeholder = null

      panorama.setPosition(view.position)
      panorama.setPov(view.pov)

      service.updatePegmanPosition(view.position)
      service.updatePegmanPov(view.pov)
    } else {
      ctrl.streetViewCtrl.placeholder = "Street View n'est pas disponible à cette adresse."
    }
  }

  function positionChanged () {
    const location = panorama.getLocation()
    const position = location && location.latLng ? location.latLng.toJSON() : null

    if (position) service.updatePegmanPosition(position)
  }

  function povChanged () {
    const pov = panorama.getPov()

    if (pov) service.updatePegmanPov(pov)
  }

  // Redrawing stuff
  // -------------------------------------------------------------------------
  function redraw () {
    resize()
    $timeout(resize, 1000)
  }

  function resize () {
    googleMaps.event.trigger(panorama, 'resize')
  }

  function getElementSize () {
    return $element[0].offsetWidth + 'x' + $element[0].offsetHeight
  }
}
