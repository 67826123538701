angular
  .module('atelier.inputs')
  .component('rangeHelperPanel', {
    controller: Controller,
    template:   require('./range_helper_panel.template.pug')(),
    bindings:   {
      input:      '=',
      inputScope: '='
    }
  })

Controller['$inject'] = ['$scope']
function Controller ($scope) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit = onInit
  ctrl.update  = updateModel

  // Watchers
  // ---------------------------------------------------------------------------
  $scope.$watch('$ctrl.inputScope.model', (value, previous) => {
    if (value === previous) return
    if (value === ctrl.model) return

    ctrl.model = value
    watchModel()
  })

  $scope.$watch('$ctrl.model', (value, previous) => {
    if (value === previous) return
    if (value === ctrl.inputScope.model) return

    ctrl.input.val(value)
    ctrl.input.triggerHandler('change')
  })

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    ctrl.model = ctrl.inputScope.model
    watchModel()
  }

  // Public methods
  // ---------------------------------------------------------------------------
  function updateModel () {
    const operator = ctrl.operator
    const value    = angular.isDefined(ctrl.value) ? ctrl.value : null
    const max      = angular.isDefined(ctrl.max) ? ctrl.max : ''

    if (operator === 'in') {
      if (value) {
        ctrl.model = value + ' - ' + max
      } else {
        ctrl.model = ''
      }
    } else if (operator === '=') {
      ctrl.model = value
    } else if (operator) {
      ctrl.model = operator + ' ' + value
    }
  }

  function watchModel () {
    const value           = angular.isDefined(ctrl.model) ? ctrl.model : ''
    const matchedCompound = /^(=<|>=|<=|=>)\s?(.*)$/.exec(value)
    const matchedOperator = /^(<|>|≤|≥)\s?(.*)$/.exec(value)
    const matchedInterval = /^(.+)\s?-\s?(.*)$/.exec(value)

    if (matchedCompound) {
      if (matchedCompound[1] === '=<' || matchedCompound[1] === '<=') {
        ctrl.operator = '≤'
      } else if (matchedCompound[1] === '=>' || matchedCompound[1] === '>=') {
        ctrl.operator = '≥'
      }

      ctrl.value = matchedCompound[2].trim()
      updateModel()
    } else if (matchedOperator) {
      ctrl.operator = matchedOperator[1]
      ctrl.value    = matchedOperator[2].trim()
    } else if (matchedInterval) {
      ctrl.operator = 'in'
      ctrl.value    = matchedInterval[1].trim()
      ctrl.max      = matchedInterval[2].trim() || ''
    } else {
      ctrl.operator = '='
      ctrl.value    = value.trim()
    }
  }
}
