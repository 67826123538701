angular
  .module('atelier.editable')
  .directive('editableCardInput', function () {
    return {
      restrict: 'A',
      require:  '^^editableCard',
      link:     link
    }
  })

function link (scope, element, attrs, editableCardCtrl) {
  element.on('focus', editableCardCtrl.focus)
  element.on('blur', editableCardCtrl.blur)
}
