L.Control.ZoomBar = L.Control.Zoom.extend({
  options: {
    position:      'topright',
    areaRectStyle: {
      className: 'leaflet-zoom-box',
      clickable: false,
      draggable: false
    }
  },
  onAdd:           onAdd,
  _zoomArea:       _zoomArea,
  _updateDisabled: _updateDisabled,
  _createButton:   _createButton
})

L.control.zoomBar = function (options) {
  return new L.Control.ZoomBar(options)
}

function onAdd (map) {
  const container = L.DomUtil.create('div', 'leaflet-control-zoom leaflet-bar')

  this._zoomInButton  = this._createButton('', 'Zoomer', 'leaflet-control-zoom-in', container, this._zoomIn)
  this._zoomOutButton = this._createButton('', 'Dézoomer', 'leaflet-control-zoom-out', container, this._zoomOut)

  if (this.options.reset) {
    this._resetZoomButton = this._createButton('', 'Retour au zoom initial', 'leaflet-control-zoom-start', container, this.options.reset)
  }

  if (!_isSmallScreen()) {
    this._zoomAreaButton = this._createButton('', 'Zoomer sur la Zone', 'leaflet-control-zoom-area', container, this._zoomArea)
  }

  this._updateDisabled()
  map.on('zoomend zoomlevelschange', this._updateDisabled, this)

  return container
}

function _isSmallScreen () {
  return (window.screen.availWidth * window.screen.availHeight) < 307456
}

function _zoomArea () {
  (this._zoomAreaActive ? _stopZoomArea : _startZoomArea).call(this)
}

function _startZoomArea () {
  this._zoomAreaActive = true

  L.DomUtil.addClass(this._zoomAreaButton, 'active')
  L.DomUtil.addClass(this._map._container, 'leaflet-zoom-on-area')

  this._map.on('mousedown', _startElasticArea, this)
}

function _stopZoomArea () {
  this._zoomAreaActive = false

  L.DomUtil.removeClass(this._zoomAreaButton, 'active')
  L.DomUtil.removeClass(this._map._container, 'leaflet-zoom-on-area')

  this._map.off('mousedown', _startElasticArea, this)
}

function _startElasticArea (event) {
  this._map.dragging.disable()

  this._zoomAreaBounds = [event.latlng, event.latlng]
  this._zoomAreaRect   = L.rectangle(this._zoomAreaBounds, this.options.areaRectStyle)

  this._map.addLayer(this._zoomAreaRect)
  this._map.on('mousemove', _updateElasticArea, this)
  this._map.on('mousedown', _stopElasticArea, this)
  this._map.on('mouseup', _stopElasticArea, this)
}

function _updateElasticArea (event) {
  this._zoomAreaBounds[1] = event.latlng
  this._zoomAreaRect.setBounds(this._zoomAreaBounds)
}

function _stopElasticArea (event) {
  _stopZoomArea.call(this)
  this._map.off('mousemove', _updateElasticArea, this)
  this._map.off('mousedown', _stopElasticArea, this)
  this._map.off('mouseup', _stopElasticArea, this)
  this._map.dragging.enable()

  if (this._zoomAreaRect) {
    const bounds = this._zoomAreaRect && this._zoomAreaRect.getBounds()
    if (bounds && bounds.isValid()) this._map.fitBounds(bounds)

    this._map.removeLayer(this._zoomAreaRect)
  }
}

function _updateDisabled () {
  L.Control.Zoom.prototype._updateDisabled.call(this)

  const className = 'leaflet-disabled'

  if (this._resetZoomButton) L.DomUtil.removeClass(this._resetZoomButton, className)
  if (this._zoomAreaButton) L.DomUtil.removeClass(this._zoomAreaButton, className)

  if (this._disabled && this._resetZoomButton) L.DomUtil.addClass(this._resetZoomButton, className)
  if (this._disabled && this._zoomAreaButton) L.DomUtil.addClass(this._zoomAreaButton, className)
}

// Hack this method to disable
function _createButton () {
  const button = L.Control.Zoom.prototype._createButton.apply(this, arguments)
  L.DomEvent.disableContextMenuPropagation(button)
  return button
}
