export const objectPath = {
  get:    get,
  set:    set,
  del:    del,
  delete: del
}

export function get (object, path) {
  if (object === undefined || object === null) return object

  if (typeof path === 'string') path = path.split('.')
  const nextPath = path[0]

  if (path.length === 1) {
    return object[nextPath]
  } else {
    return get(object[nextPath], path.slice(1))
  }
}

export function set (object, path, value) {
  if (typeof path === 'string') path = path.split('.')
  const nextPath = path[0]

  if (path.length === 1) {
    object[nextPath] = value
  } else {
    object[nextPath] = object[nextPath] || {}
    set(object[nextPath], path.slice(1), value)
  }
}

export function del (object, path) {
  if (object === undefined || object === null) return

  if (typeof path === 'string') path = path.split('.')
  const nextPath = path[0]

  if (path.length === 1) {
    return delete object[nextPath]
  } else {
    return del(object[nextPath], path.slice(1))
  }
}
