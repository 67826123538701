angular
  .module('atelier.navbar')
  .component('navbarCollectiviteSwitch', {
    controller: NavbarCollectiviteSwitchController,
    template:   require('./collectivite_switch.template.pug')()
  })

NavbarCollectiviteSwitchController['$inject'] = ['$http', '$window', '$mdDialog', '$filter']
function NavbarCollectiviteSwitchController ($http, $window, $mdDialog, $filter) {
  const ctrl = this

  // Bindable members
  // -------------------------------------------------------------------------
  ctrl.$onInit = onInit
  ctrl.cancel  = cancel
  ctrl.submit  = submit

  ctrl.autocomplete = {
    closedList: false,
    searchText: null,
    items:      search
  }

  // Component hooks
  // -------------------------------------------------------------------------
  function onInit () {
    getCollectivites().then(function (results) {
      ctrl.autocomplete.closedList = results.closed_list
      ctrl.ready = true
    })
  }

  // Public functions
  // -------------------------------------------------------------------------
  function cancel () {
    return $mdDialog.cancel()
  }

  function search () {
    if (ctrl.autocomplete.closedList) {
      // return getCollectivites().then(function (result) {
      //   return $filter('autocomplete')(result.collectivites, ctrl.autocomplete.searchText || '')
      // })
      return getCollectivites(ctrl.autocomplete.searchText).then(function (result) {
        return result.collectivites
      })
    } else {
      return getCollectivites(ctrl.autocomplete.searchText).then(function (result) {
        return result.collectivites
      })
    }
  }

  function submit () {
    ctrl.form.$submitted = true

    return $http.put('/users_v3/collectivite', {
      collectivite: ctrl.collectivite
    }).then(function () {
      // Do not set $submitted to false,
      // Do not hide dalog.
      // Let's just reload the page
      $window.location.reload()
    }).catch(function () {
      ctrl.form.$setPristine()
    })
  }

  // Private functions
  // -------------------------------------------------------------------------
  function getCollectivites (q) {
    const params = {}

    if (q && q.length) {
      params.q = q
    }

    return $http.get('/users_v3/collectivites', {
      cache:  true,
      params: params
    }).then(function (response) {
      return response.data
    })
  }
}
