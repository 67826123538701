angular
  .module('atelier.GIS')
  .factory('GIS', GIS)

GIS['$inject'] = [
  'GIS.Config',
  'GIS.Factory',
  'GIS.StreetView'
]
function GIS (
  Config,
  Factory,
  StreetView
) {
  return {
    Config:     Config,
    Factory:    Factory,
    StreetView: StreetView
  }
}
