L.Angular = {
  get:     get,
  compile: compile
}

function get (dependency) {
  const injector = angular.element(document).injector()
  return injector.get(dependency)
}

function compile (container, html, data) {
  const element  = angular.element(container)
  const $compile = get('$compile')
  const scope    = get('$rootScope').$new(true)

  element.html(html)
  angular.extend(scope, data)
  $compile(element)(scope)

  return scope
}
