angular
  .module('atelier.GIS')
  .factory('GIS.StreetView', StreetView)

StreetView['$inject'] = ['thirdParty', '$q', '$window', '$http']
function StreetView (thirdParty, $q, $window, $http) {
  return {
    getPanorama:  getPanorama,
    getStaticUrl: getStaticUrl
  }

  // -------------------------------------------------------------------------
  function getPanorama (value) {
    if (value.lon && !value.lng) value.lng = value.lon

    return thirdParty.inject('googleMaps').then(function (googleMaps) {
      const streetViewService = new googleMaps.StreetViewService()
      const point             = new googleMaps.LatLng(value)
      const deferred          = $q.defer()

      streetViewService.getPanorama({
        location: point,
        radius:   250
      }, function (data, status) {
        if (status !== googleMaps.StreetViewStatus.OK) {
          return deferred.reject('getPanorama response status is not OK (' + status + ')')
        }

        const latLng  = data.location.latLng
        const heading = googleMaps.geometry.spherical.computeHeading(point, latLng) + 180
        const pitch   = 0

        return deferred.resolve({
          position: {
            lat: latLng.lat(),
            lng: latLng.lng()
          },
          pov: {
            pitch:   pitch,
            heading: heading
          }
        })
      })

      return deferred.promise
    })
  }

  function getStaticUrl (point, size) {
    return getPanorama(point).then(function (response) {
      let url = '/api/geo/streetview/' + response.position.lat + '/' + response.position.lng
      url += '?size=' + size[0] + 'x' + size[1]
      url += '&heading=' + response.pov.heading
      url += '&pitch=' + response.pov.pitch

      return $http.get(url, {
        cache: true
      })
    }).then(function (response) {
      return response.data.url
    })
  }
}
