angular
  .module('atelier.GIS')
  .service('GIS.Factory', Service)

Service['$inject'] = [
  'GIS.Config', 'GIS.StreetView', 'thirdParty', 'eventHandlers',
  '$q', '$window', '$cacheFactory', '$timeout'
]
function Service (
  Config, StreetView, thirdParty, eventHandlers,
  $q, $window, $cacheFactory, $timeout
) {
  function GisFactory (options) {
    initialize.call(this, options)
    return this
  }

  eventHandlers.extendPrototype(GisFactory)

  GisFactory.prototype.resolve  = resolve
  GisFactory.prototype.get      = get
  GisFactory.prototype.set      = set

  // Getters
  GisFactory.prototype.getOverlays        = getOverlays
  GisFactory.prototype.getProviders       = getProviders
  GisFactory.prototype.getDefaultBounds   = getDefaultBounds
  GisFactory.prototype.getDefaultOverlays = getDefaultOverlays
  GisFactory.prototype.getDefaultProvider = getDefaultProvider
  GisFactory.prototype.getExtraBasemaps   = getExtraBasemaps
  GisFactory.prototype.checkProvider      = checkProvider

  // Actions
  GisFactory.prototype.redraw               = redraw
  GisFactory.prototype.clearMarkers         = clearMarkers
  GisFactory.prototype.updateMarkers        = updateMarkers
  GisFactory.prototype.updateFocused        = updateFocused
  GisFactory.prototype.openOneBox           = openOneBox
  GisFactory.prototype.showControls         = showControls
  GisFactory.prototype.hideControls         = hideControls
  GisFactory.prototype.showOverlays         = showOverlays
  GisFactory.prototype.hideOverlays         = hideOverlays
  GisFactory.prototype.showGeoJsonLayer     = showGeoJsonLayer
  GisFactory.prototype.hideGeoJsonLayer     = hideGeoJsonLayer
  GisFactory.prototype.requireScreenShot    = requireScreenShot

  // StreetView actions
  GisFactory.prototype.requestStreetView    = requestStreetView
  GisFactory.prototype.updateStreetView     = updateStreetView
  GisFactory.prototype.updatePegmanPosition = updatePegmanPosition
  GisFactory.prototype.updatePegmanPov      = updatePegmanPov

  return GisFactory

  // Initialization
  // -------------------------------------------------------------------------
  function initialize (options) {
    const gis = this
    options = angular.extend({}, Config, options)

    angular.forEach(Config.layers, function (config, name) {
      if (angular.isFunction(config)) {
        options.layers[name] = options.layers[name] || config
      } else {
        options.layers[name] = angular.extend({}, config, options.layers[name])
      }
    })

    gis.data      = {}
    gis.callbacks = {}
    gis.providers = options.providers || []
    gis.overlays  = options.overlays || {}
    gis.options   = options

    if (options.promise) {
      gis.resolve(options.promise)
    }

    const cacheStore = this.options.cacheStore
    gis.cache = $cacheFactory.get(cacheStore) || $cacheFactory(cacheStore)
  }

  function resolve (promise) {
    const gis = this

    gis.promise = $q.resolve(promise).then(function (data) {
      if (data) {
        if (angular.isArray(data.bounds)) {
          gis.options.bounds = data.bounds
        }

        if (angular.isArray(data.defaultOverlays)) {
          gis.options.defaultOverlays = data.defaultOverlays
        }

        if (data.defaultProvider) {
          gis.options.defaultProvider = data.defaultProvider
        }

        if (data.defaultBasemap) {
          gis.options.defaultBasemap = data.defaultBasemap
        }

        if (angular.isArray(data.providers)) {
          gis.providers = data.providers
        }

        if (data.overlays) {
          gis.overlays = data.overlays
        }

        if (data.extraBasemaps) {
          gis.extraBasemaps = data.extraBasemaps
        }

        gis.promisedData = data
      }
    })

    return gis.promise
  }

  // Getters
  // -------------------------------------------------------------------------
  function getDefaultBounds () {
    const gis = this

    return $q.when(gis.promise).then(function () {
      if (angular.isArray(gis.options.bounds)) {
        return gis.options.bounds
      } else {
        return $q.reject('Default bounds not available')
      }
    })
  }

  function getDefaultOverlays () {
    const gis = this

    return $q.when(gis.promise).then(function () {
      if (angular.isArray(gis.options.defaultOverlays)) {
        return gis.options.defaultOverlays
      } else {
        return 'collectivite sections parcelles locaux surfaces'.split(' ')
      }
    })
  }

  function getDefaultProvider () {
    const gis = this

    return gis.getProviders().then(function (providers) {
      if (providers.includes(gis.options.defaultProvider)) {
        return gis.options.defaultProvider
      } else {
        return providers[0]
      }
    })
  }

  function getExtraBasemaps () {
    const gis = this

    return $q.when(gis.promise).then(function () {
      return gis.extraBasemaps
    })
  }

  function getOverlays () {
    const gis = this

    return $q.when(gis.promise).then(function () {
      return gis.overlays
    })
  }

  function getProviders () {
    const gis = this

    return $q.when(gis.promise).then(function () {
      const allowed   = gis.options.providers || []
      let providers = gis.providers || []

      providers = providers.filter(function (provider) {
        return allowed.indexOf(provider) > -1
      })

      return providers
    })
  }

  // Providers check
  // -------------------------------------------------------------------------
  function checkProvider (name) {
    return this.getProviders().then(function (providers) {
      if (providers.indexOf(name) > -1) {
        return $q.resolve()
      } else {
        return $q.reject('Provider ' + name + ' not available')
      }
    })
  }

  // Actions
  // -------------------------------------------------------------------------
  function redraw () {
    this.trigger('redraw')
  }

  function clearMarkers () {
    this.data.markers = []
    this.data.focused = []
    this.trigger('markersUpdated', [])
    this.trigger('focusedUpdated', [])
  }

  function updateMarkers (markers) {
    this.data.markers = markers
    this.trigger('markersUpdated', markers)
  }

  function updateFocused (markers) {
    this.data.focused = markers
    this.trigger('focusedUpdated', markers)
  }

  function showControls () {
    this.options.showControls = true
    this.trigger('request:showControls')
  }

  function hideControls () {
    this.options.showControls = false
    this.trigger('request:hideControls')
  }

  function openOneBox (options) {
    this.trigger('request:openOneBox', options)
  }

  function showOverlays (uids) {
    this.data.requestedOverlays = uids
    this.trigger('request:showOverlays', uids)
  }

  function hideOverlays (uids) {
    let requested = this.data.requestedOverlays || []

    if (uids && uids.length) {
      uids.forEach(function (uid) {
        const index = requested.indexOf(uid)
        if (index > -1) requested.splice(index, 1)
      })
    } else {
      requested = []
    }

    this.data.requestedOverlays = requested
    this.trigger('request:hideOverlays', uids)
  }

  function showGeoJsonLayer (json) {
    this.data.requestedGeoJsonLayer = json
    this.trigger('request:showGeoJsonLayer', json)
  }

  function hideGeoJsonLayer () {
    this.data.requestedGeoJsonLayer = null
    this.trigger('request:hideGeoJsonLayer')
  }

  function requireScreenShot () {
    this.trigger('request:screenShot')
  }

  // StreetView actions
  // -------------------------------------------------------------------------
  function requestStreetView (position) {
    this.data.streetView = null
    this.trigger('request:streetView', position)

    return StreetView.getPanorama(position).then(
      updateStreetView.bind(this),
      updateStreetView.bind(this)
    )
  }

  function updateStreetView (params) {
    const previous = this.data.streetView
    if (angular.equals(params, previous)) return

    this.data.streetView = params
    this.trigger('streetViewUpdated', params)
  }

  function updatePegmanPosition (position) {
    const streetView = this.data.streetView || {}
    if (angular.equals(position, streetView.position)) return

    angular.extend(this.data.streetView, { position: position })
    this.trigger('pegmanPositionUpdated', position)
  }

  function updatePegmanPov (pov) {
    const streetView = this.data.streetView || {}
    if (angular.equals(pov, streetView.pov)) return

    angular.extend(this.data.streetView, { pov: pov })
    this.trigger('pegmanPovUpdated', pov)
  }

  // Properties setter/getter
  // -------------------------------------------------------------------------
  function get (name) {
    const getterMethod = 'get' + upperPropertyName(name)

    if (angular.isFunction(this[getterMethod])) {
      return this[getterMethod]()
    } else {
      return this.data[name]
    }
  }

  function set (name, value) {
    const setterMethod = this['set' + upperPropertyName(name)]

    if (angular.isFunction(setterMethod)) {
      setterMethod.call(this, value)
    } else {
      const previous = this.data[name]
      if (angular.equals(value, previous)) return

      this.data[name] = value
      this.trigger(name + 'Changed', value, previous)
    }
  }

  function upperPropertyName (name) {
    return name[0].toUpperCase() + name.slice(1)
  }
}
