angular
  .module('atelier.config')
  .filter('config', configFilter)

configFilter['$inject'] = ['appConfig']
function configFilter (appConfig) {
  return function (key, scope, noError) {
    if (scope) key = scope + '.' + key

    const value = appConfig.find(key)

    if (angular.isUndefined(value) && !noError) {
      console.error('undefined config value: ' + key)
      return null
    } else {
      return value
    }
  }
}
