angular
  .module('atelier.labelize')
  .filter('labelize', labelizeFilter)

labelizeFilter['$inject'] = ['labelize']
function labelizeFilter (labelize) {
  return function (key, scope, noError) {
    if (scope) key = scope + '.' + key

    const value = labelize(key)

    if (angular.isUndefined(value) && !noError) {
      console.error('undefined label path ' + key)
      return null
    } else {
      return value
    }
  }
}
