angular
  .module('atelier.navbar')
  .component('navbar', {
    controller: NavbarController,
    template:   require('./navbar.template.pug')(),
    transclude: {
      brand: 'navbarBrand',
      menu:  '?navbarMenu',
      user:  '?navbarUser'
    }
  })

NavbarController['$inject'] = ['$http', '$window', '$element', '$timeout', 'dialogComponent', 'CSRF']
function NavbarController ($http, $window, $element, $timeout, dialogComponent, CSRF) {
  const ctrl = this
  let menu, context

  // Functions
  ctrl.$onInit                = onInit
  ctrl.openAccountForm        = openAccountForm
  ctrl.openBuilderForm        = openBuilderForm
  ctrl.openEmailForm          = openEmailForm
  ctrl.openEmailPasswordForm  = openEmailPasswordForm
  ctrl.open2faForm            = open2faForm
  ctrl.openSwitchForm         = openSwitchForm
  ctrl.signout                = signout
  ctrl.loadMoreNotifications  = loadMoreNotifications
  ctrl.dismissNotification    = dismissNotification
  ctrl.dismissAllNotification = dismissAllNotification

  // Hooks
  // -------------------------------------------------------------------------
  function onInit () {
    startDetectingCollision()

    $http.get('/users_v3/account', {
      ignoreLoadingBar: true
    }).then(function (response) {
      ctrl.account = response.data
    })

    $http.get('/d/notifications', {
      ignoreLoadingBar: true
    }).then(function (response) {
      ctrl.notifications              = response.data.notifications
      ctrl.notifications.total_count  = response.data.total_count
      ctrl.notifications.unseen_count = response.data.unseen_count
    })
  }

  // Public functions
  // -------------------------------------------------------------------------
  function openAccountForm (event) {
    return dialogComponent.show({
      template:    '<navbar-account-form>',
      ariaLabel:   'Mettre à jour mes informations',
      targetEvent: event
    })
  }

  function openBuilderForm (event) {
    return dialogComponent.show({
      template:    '<navbar-builder-form>',
      ariaLabel:   'Configurer mon accès à Builder',
      targetEvent: event
    })
  }

  function openEmailForm (event) {
    return dialogComponent.show({
      template:    '<navbar-email-form>',
      ariaLabel:   'Configurer la synchronisation des emails',
      targetEvent: event
    })
  }

  function open2faForm (event) {
    return dialogComponent.show({
      template:    '<navbar-2fa-form>',
      multiple:    true,
      ariaLabel:   "Activer ou désactiver l'authentification en 2 étapes",
      targetEvent: event
    })
  }

  function openEmailPasswordForm (event) {
    return dialogComponent.show({
      template:    '<navbar-email-password-form>',
      multiple:    true,
      ariaLabel:   'Mettre à jour mes identifiants',
      targetEvent: event
    })
  }

  function openSwitchForm (event) {
    return dialogComponent.show({
      template:    '<navbar-collectivite-switch>',
      ariaLabel:   'Changer de collectivité',
      targetEvent: event
    })
  }

  function signout () {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', '/sign_out')

    const inputCSRF = document.createElement('input')
    inputCSRF.setAttribute('type', 'hidden')
    inputCSRF.setAttribute('name', Object.keys(CSRF)[0])
    inputCSRF.setAttribute('value', Object.values(CSRF)[0])

    const inputMethod = document.createElement('input')
    inputMethod.setAttribute('type', 'hidden')
    inputMethod.setAttribute('name', '_method')
    inputMethod.setAttribute('value', 'delete')

    form.appendChild(inputCSRF)
    form.appendChild(inputMethod)

    document.body.appendChild(form)
    form.submit()
  }

  function loadMoreNotifications () {
    const array  = ctrl.notifications
    const lastId = array[array.length - 1].id

    $http.get('/d/notifications', {
      params:           { before: lastId },
      ignoreLoadingBar: true
    }).then(function (response) {
      array.push.apply(array, response.data.notifications)
    })
  }

  function dismissNotification (notification) {
    notification.seen = true
    ctrl.notifications.unseen_count -= 1

    $http.put('/d/notifications/' + notification.id, {}, { ignoreLoadingBar: true })
  }

  function dismissAllNotification () {
    ctrl.notifications.unseen_count = 0
    ctrl.notifications.forEach(function (notification) {
      notification.seen = true
    })

    $http.put('/d/notifications', {}, { ignoreLoadingBar: true })
  }

  // Private functions
  // -------------------------------------------------------------------------
  function startDetectingCollision () {
    menu    = $element.find('.navbar-menu-default navbar-menu')
    context = $element.find('.navbar-context')

    if (!menu.length || !context.length) {
      $timeout(startDetectingCollision, 50)
      return
    }

    const a = menu[0].getBoundingClientRect()
    const b = context[0].getBoundingClientRect()

    if (!a.width || !b.width) {
      $timeout(startDetectingCollision, 50)
      return
    }

    delectCollision()
    angular.element($window).on('resize', delectCollision)
  }

  function delectCollision () {
    const a = menu[0].getBoundingClientRect()
    const b = context[0].getBoundingClientRect()

    // Because this function may be called outside scope digest on resize
    // We need to wrap changes in $timeout
    $timeout(function () {
      ctrl.mobileVersion = (a.left + a.width >= b.left)
    })
  }
}
