import config from './config.js'

angular
  .module('atelier.thirdParty', [])
  .factory('thirdParty', thirdParty)

thirdParty['$inject'] = ['$q', '$timeout']
function thirdParty ($q, $timeout) {
  const factory = {
    inject:    inject,
    injectUrl: injectUrl
  }

  const cache = {}

  return factory

  // Public functions
  // -----------------------------------------------------------------------
  function inject (name) {
    let deferred = cache[name]
    if (deferred) return deferred.promise

    const loader = config[name]
    if (!loader) return $q.reject('unknown third party: ' + name)

    deferred = cache[name] = $q.defer()
    deferred.resolve(loader(factory))

    return deferred.promise
  }

  function injectUrl (url, check) {
    let deferred = cache[url]
    if (deferred) return deferred.promise

    deferred = cache[url] = $q.defer()

    verify(deferred, function () {
      try {
        return check()
      } catch (e) {}
    })

    angular.element('<script src="' + url + '"></script>').appendTo(document.head)

    return deferred.promise
  }

  // Private functions
  // -----------------------------------------------------------------------
  function verify (deferred, check) {
    const result = check()

    if (result) {
      deferred.resolve(result)
    } else {
      $timeout(function () {
        verify(deferred, check)
      }, 500)
    }
  }
}
