/**
 * Handles the display and behaviour of markers
 */
angular
  .module('atelier.GIS')
  .factory('LeafletModules.Markers', Markers)

Markers['$inject'] = ['GIS.Config']
function Markers (Config) {
  return function () {
    const mod  = this
    let leaflet, service, options, layer

    // Hooks
    mod.$onInit     = onInit
    mod.$onDestroy  = onDestroy

    // Functions
    mod.add         = addMarker
    mod.focus       = updateFocused
    mod.reset       = resetLayer
    mod.update      = updateMarkers

    // Hooks
    // -----------------------------------------------------------------------
    function onInit () {
      leaflet = mod.$leaflet
      service = mod.$service
      options = service.options
      layer   = mod.layer = L.markerClusterGroup(options.markerClusterGroup).addTo(leaflet.map)

      updateMarkers(service.get('markers'))
      updateFocused(service.get('focused'))

      service.on('markersUpdated', updateMarkers)
      service.on('focusedUpdated', updateFocused)
    }

    function onDestroy () {
      service.off('markersUpdated', updateMarkers)
      service.off('focusedUpdated', updateFocused)
    }

    // Public functions
    // -------------------------------------------------------------------------
    function addMarker (properties) {
      const latlng = L.latLng(properties)
      if (!latlng || angular.isUndefined(latlng)) return

      const marker = L.marker(latlng, {
        icon:         buildMarkerIcon(properties),
        zIndexOffset: options.markersIndex.default
      })

      marker.properties = properties
      marker.focus      = focusMarker.bind(this, marker)
      marker.blur       = blurMarker.bind(this, marker)

      marker.addTo(layer)
      marker.on('click', function (event) {
        leaflet.contextMenu.open(latlng, {
          type:  'marker',
          layer: marker
        })
      })
    }

    function updateFocused (items) {
      const points   = []
      const selected = []

      angular.forEach(items, function (item) {
        const latlng = L.latLng(item)
        if (!latlng || angular.isUndefined(latlng)) return

        points.push(latlng.toString())
      })

      layer.eachLayer(function (marker) {
        if (points.indexOf(marker.getLatLng().toString()) > -1) {
          focusMarker(marker)
          selected.push(marker)
        } else {
          blurMarker(marker)
          marker.focused = false
        }
      })

      if (selected.length && options.resetBoundsOnFocused && leaflet.bounds) {
        leaflet.bounds.update(selected)
      }
    }

    function resetLayer () {
      layer.clearLayers()
    }

    function updateMarkers (items) {
      resetLayer()
      angular.forEach(items, addMarker)

      if (items && items.length && options.resetBoundsOnMarkers && leaflet.bounds) {
        leaflet.bounds.update(items)
      }
    }

    // Private functions
    // -------------------------------------------------------------------------
    function buildMarkerIcon (properties, current) {
      let iconOptions

      if (properties.type) {
        iconOptions = angular.copy(options.customIcon)
        iconOptions.className += ' ' + options.customIcon.classTypePrefix + properties.type
      } else {
        iconOptions = angular.copy(options.defaultIcon)
      }

      if (properties.current) current = true

      if (current) {
        const className = options.currentIcon.className + ' ' + iconOptions.className

        iconOptions = angular.extend(iconOptions, options.currentIcon, {
          className: className
        })
      }

      return L.divIcon(iconOptions)
    }

    function focusMarker (marker) {
      marker.setIcon(buildMarkerIcon(marker.properties, true))
      marker.setZIndexOffset(service.options.markersIndex.focus)
    }

    function blurMarker (marker) {
      marker.setIcon(buildMarkerIcon(marker.properties, false))
      marker.setZIndexOffset(service.options.markersIndex.default)
    }
  }
}
