angular.module('atelier.navbar', [
  'atelier.dialogComponent',
  'material.components.dialog'
])

require('./services/current.factory.js')
require('./components/navbar.component.js')
require('./components/button.component.js')
require('./components/dropdown.component.js')

require('./components/modals/collectivite_switch.component.js')
