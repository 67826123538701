angular
  .module('atelier.editable')
  .component('editablePanel', {
    controller: PanelController,
    template:   require('./panel.template.pug')(),
    transclude: true,
    require:    {
      editableCtrl: '^^editable'
    },
    bindings: {
      onOpen:   '&',
      onClose:  '&',
      onSubmit: '&',
      noHint:   '<'
    }
  })

PanelController['$inject'] = []
function PanelController () {
  const ctrl = this

  ctrl.$onInit = onInit
  ctrl.submit  = submit

  function onInit () {
    ctrl.editableCtrl.onOpen  = ctrl.onOpen
    ctrl.editableCtrl.onClose = ctrl.onClose
  }

  function submit () {
    ctrl.onSubmit()
    ctrl.form.$setPristine()
    ctrl.editableCtrl.close()
  }
}
