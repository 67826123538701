// HTML (pug) Example:
//
//  table( expandable )
//    tr(
//      ng-repeat-start="item in collection"
//      ng-class="{ current: expandable.expanded(item) }"
//    )
//      td.actionrow
//        expand-button( expand="item" )
//        showcase-button( ... )
//
//      td {{ item.title }}
//
//    tr.expandable( ng-if="expandable.expanded(item)" )
//      td.expanded( colspan=99 )
//        ...
//
angular
  .module('atelier.expandable')
  .directive('expandable', directive)

directive['$inject'] = []
function directive () {
  return {
    restrict:     'A',
    scope:        true,
    controller:   Controller,
    controllerAs: 'expandable'
  }
}

// Controller['$inject'] = []
function Controller () {
  const expandable = this

  // Bindable members
  // ---------------------------------------------------------------------------
  expandable.collapse = collapse
  expandable.expand   = expand
  expandable.expanded = expanded
  expandable.toggle   = toggle

  // Public functions
  // ---------------------------------------------------------------------------
  function collapse () {
    expandable.current = null
  }

  function expand (object) {
    expandable.current = object
  }

  function expanded (object) {
    return expandable.current && match(expandable.current, object)
  }

  function toggle (object) {
    expanded(object) ? collapse() : expand(object)
  }

  // Private functions
  // ---------------------------------------------------------------------------
  function match (a, b) {
    // if (expandable.match) {
    //   return a[expandable.match] == b[expandable.match]
    // } else {
    return angular.equals(a, b)
  }
}
