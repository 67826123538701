require('./L.Angular')

L.OneBox = L.Class.extend({
  initialize: initialize,
  open:       open,
  close:      close
})

L.oneBox = function (map, options) {
  return new L.OneBox(map, options)
}

function initialize (map, options) {
  const container = L.DomUtil.create('div', 'leaflet-onebox', map._container)

  this._map       = map
  this._options   = options
  this._container = container
  this._visible   = false

  L.DomEvent.disableClickPropagation(container)
  L.DomEvent.disableContextMenuPropagation(container)
}

function open () {
  let html, params

  if (typeof arguments[0] === 'string') {
    html   = arguments[0]
    params = arguments[1]
  } else if (typeof arguments[0] === 'object') {
    html   = arguments[0].template
    params = arguments[0].params
  } else {
    return
  }

  if (this._scope) this._scope.$destroy()

  params = angular.extend({}, this._options, params)

  this._scope = L.Angular.compile(this._container, html, params)
  this._container.style.display = 'block'
  this._visible = true

  this._map.fire('onebox.show')

  return this
}

function close () {
  if (this._visible) {
    this._visible = false
    this._container.style.display = 'none'

    if (this._scope) this._scope.$destroy()

    this._map.fire('onebox.hide')
  }

  return this
}
