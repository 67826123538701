angular
  .module('atelier.explore')
  .component('explore', {
    controller: Controller,
    template:   require('./explore.template.pug')(),
    transclude: true,
    bindings:   {
      url: '=href'
    }
  })

Controller['$inject'] = ['$http', '$scope', '$element', '$transclude']
function Controller ($http, $scope, $element, $transclude) {
  const ctrl = this
  let initiated, transcludedContent, transclusionScope

  // Bindable members
  // -------------------------------------------------------------------------
  ctrl.$onInit        = onInit
  ctrl.$onChanges     = onChanges
  ctrl.$onDestroy     = onDestroy

  $scope.$exploreCtrl = ctrl

  // Component hooks
  // -------------------------------------------------------------------------
  function onInit () {
    if (ctrl.url) reload()
    initiated = true
  }

  function onChanges (changes) {
    if (!initiated) return

    if (changes.url.currentValue) {
      reload()
    } else {
      unload()
    }
  }

  function onDestroy () {
    unload()
  }

  // Private functions
  // -------------------------------------------------------------------------
  function reload () {
    ctrl.ready = false

    return $http.get(ctrl.url, {
      cache: true
    }).then((response) => {
      const data = angular.copy(response.data)

      ctrl.data  = data
      ctrl.ready = true

      $transclude((clone, scope) => {
        scope.$explore = data
        $element.find('explore-transclude').append(clone)

        transcludedContent = clone
        transclusionScope  = scope
      })
    })
  }

  function unload () {
    ctrl.ready = false

    if (transcludedContent) transcludedContent.remove()
    if (transclusionScope) transclusionScope.$destroy()

    transcludedContent = null
    transclusionScope  = null
  }
}
