angular
  .module('atelier.uploaders')
  .component('filesUploader', {
    controller: Controller,
    template:   require('./files_uploader.template.pug')(),
    transclude: {
      hint: '?filesUploaderHint'
    },
    bindings: {
      files:    '=',
      maxSize:  '<',
      onAdd:    '&',
      onRemove: '&'
    }
  })

Controller['$inject'] = ['$element', '$timeout', '$q', '$mdDialog', 'FileUploader', 'ActiveStorage']
function Controller ($element, $timeout, $q, $mdDialog, FileUploader, ActiveStorage) {
  const ctrl = this

  ctrl.add    = add
  ctrl.remove = remove
  ctrl.$onInit = onInit

  ctrl.uploader = new FileUploader({
    autoUpload:        false,
    onAfterAddingFile: afterAddingFile,
    onSuccessItem:     afterUploadingFile,
    onErrorItem:       afterUploadError
  })

  // Hook
  // -----------------------------------------------------------------------
  function onInit () {
    if (ctrl.maxSize) ctrl.uploader.queueLimit = ctrl.maxSize
  }

  // Public functions
  // -----------------------------------------------------------------------
  function add () {
    if (ctrl.clickTriggered) return

    $timeout(function () {
      ctrl.clickTriggered = true
      $element.find('input[type="file"]').click()
      ctrl.clickTriggered = false
    })
  }

  function remove (file, event) {
    $mdDialog.show({
      controllerAs:        '$ctrl',
      template:            require('./remove.template.pug')(),
      openFrom:            event,
      bindToController:    true,
      clickOutsideToClose: true,
      escapeToClose:       true,
      multiple:            true,
      controller:          function () {
        this.cancel  = $mdDialog.cancel
        this.confirm = function () {
          removeFileFromCollection(file)
          ctrl.onRemove({ $file: file })

          const index = ctrl.files.indexOf(file)
          ctrl.files.slice(index, 1)

          $mdDialog.hide()
        }
      }
    }).catch(function (error) {
      if (error !== 'canceled') return $q.reject(error)
    })
  }

  // Private functions
  // -----------------------------------------------------------------------
  // -- upload
  function afterAddingFile (item) {
    const file = {
      uploading: true,
      filename:  item.file.name
    }

    ctrl.files.push(file)
    item.file = file

    ActiveStorage.sign(item._file).then(response => {
      item.file.attachment  = response.data.signed_id
      item.url              = response.data.direct_upload.url
      item.headers          = response.data.direct_upload.headers
      item.formData         = response.data
      item.method           = 'PUT'
      item.useCORS          = true
      item.disableMultipart = true
      item.upload()
    })
  }

  function afterUploadingFile (item) {
    ctrl.onAdd({ $file: item.file })
  }

  function afterUploadError (item) {
    removeFileFromCollection(item.file)
  }

  function removeFileFromCollection (file) {
    const index = ctrl.files.indexOf(file)

    if (file.id) {
      file._destroy = true
    } else if (index !== -1) {
      ctrl.files.splice(index, 1)
    }
  }
}
