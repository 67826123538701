/**
 * Binds to streetView events to update the position and point of view of the
 * current marker accordingly
 */
angular
  .module('atelier.GIS')
  .factory('LeafletModules.Pegman', Pegman)

Pegman['$inject'] = ['$timeout']
function Pegman ($timeout) {
  return function () {
    const mod  = this
    let leaflet, service, options, marker, icon

    // Hooks
    mod.$onInit     = onInit
    mod.$onDestroy  = onDestroy

    // Hooks
    // -----------------------------------------------------------------------
    function onInit () {
      leaflet = mod.$leaflet
      service = mod.$service
      options = service.options

      icon    = L.divIcon(service.options.pegmanIcon)
      marker  = L.marker(null, {
        draggable:    true,
        icon:         icon,
        zIndexOffset: options.markersIndex.pegman
      })

      service.on('streetViewVisibleChanged', updateMarker)
      service.on('streetViewUpdated', updateMarker)
      service.on('pegmanPositionUpdated', updatePosition)
      service.on('pegmanPovUpdated', updatePov)
      marker.on('dragend', requestNewPosition)

      updateMarker()
    }

    function onDestroy () {
      service.off('streetViewVisibleChanged', updateMarker)
      service.off('streetViewUpdated', updateMarker)
      service.off('pegmanPositionUpdated', updatePosition)
      service.off('pegmanPovUpdated', updatePov)
      marker.off('dragend', requestNewPosition)
    }

    // Private functions
    // -----------------------------------------------------------------------
    function updateMarker () {
      const view = service.get('streetView') || {}

      updatePosition(view.position)
      updatePov(view.pov)
    }

    function updatePosition (position) {
      if (service.get('streetViewVisible') && options.showPegman && position) {
        const latlng = L.latLng(position)

        marker.setLatLng(latlng).addTo(leaflet.map)

        // Ensure that map has the right size before set center
        leaflet.map.invalidateSize({ animate: false, pan: false })
        leaflet.map.setView(latlng)
      } else {
        marker.remove()
      }
    }

    function updatePov (pov) {
      if (!pov) return

      // The sprite include 16 positions of the pegman
      // 22.5 = 360 / 16
      const rotationPosition = Math.round(pov.heading / 22.5)
      icon.options.className = service.options.pegmanIcon.className + ' rotated-' + rotationPosition

      marker.setIcon(icon)
    }

    function requestNewPosition () {
      service.requestStreetView(marker.getLatLng())
    }
  }
}
