require('./L.Angular')

L.GeoJSON.HTTP.Sync = L.GeoJSON.HTTP.extend({
  onAdd:       onAdd,
  onRemove:    onRemove,
  update:      update,
  _verifyZoom: _verifyZoom
})

L.geoJson.http.sync = function (url, options) {
  return new L.GeoJSON.HTTP.Sync(url, options)
}

function onAdd (map) {
  L.GeoJSON.HTTP.prototype.onAdd.call(this, map)

  map.on('load', update, this)
  map.on('moveend', update, this)
}

function onRemove (map) {
  L.GeoJSON.HTTP.prototype.onRemove.call(this, map)

  map.off('load', update, this)
  map.off('moveend', update, this)
}

function update () {
  const self  = this
  const $q    = L.Angular.get('$q')
  const $http = L.Angular.get('$http')

  if (self._canceler) self._canceler.resolve()
  self._canceler = $q.defer()

  if (!self._map) return

  if (!self._verifyZoom()) {
    self.clearLayers()
    return
  }

  const bounds = self._map.getBounds()
  const url    = self._url + [bounds.getNorth(), bounds.getEast(), bounds.getSouth(), bounds.getWest()].join('/')

  return $http.get(url, {
    timeout: self._canceler.promise
  }).then(
    function (results) {
      if (!self._verifyZoom()) return
      self.clearLayers()
      self.addData(results.data.features)
    },
    function (response) {
      if (response.xhrStatus !== 'abort') return $q.reject(response)
    }
  )
}

function _verifyZoom () {
  const map     = this._map
  const minZoom = this.options.minZoom
  const maxZoom = this.options.maxZoom

  return map && !(minZoom && minZoom > map.getZoom()) && !(maxZoom && maxZoom < map.getZoom())
}
