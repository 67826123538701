angular
  .module('atelier.announcement')
  .run(Announcer)

Announcer['$inject'] = ['$http', 'dialogComponent']
function Announcer ($http, dialogComponent) {
  $http.get('/d/announces', {
    ignoreLoadingBar: true
  }).then(function (response) {
    const announces = response.data.announces
    if (announces.length === 0) return

    dialogComponent.show({
      template:            '<announcement announces="$dialog.announces">',
      ariaLabel:           'Dernières annonces',
      clickOutsideToClose: false,
      escapeToClose:       false,
      locals:              {
        announces: announces
      }
    })
  }).catch(function (rejection) {
    if (rejection.status !== 401) return rejection
  })
}
