// -----------------------------------------------------------------------------
//
//   Fomat numbers using a string pattern
//
//     123456.89745 | format:'%n'         => "123 456,897"
//     123456.89745 | format:'%d'         => "123 456,89745"
//     123456.89745 | format:'%s'         => "123456.89745"
//
//   Default precision used by %n is 3 (set by angular locale)
//   With precision pattern:
//
//     123456.897 | format:'%n0'       => "123 456"
//     123456.897 | format:'%n1'       => "123 456,9"
//     123456.897 | format:'%n2'       => "123 456,90"
//
//   With a prefix, a suffix, any text:
//
//     123456.897 | format:'$ %n'      => "$ 123 456,897"
//     123456.897 | format:'%n €'      => "123 456,897 €"
//     123456.897 | format:'%n0 %'     => "123 456 %"
//
// -----------------------------------------------------------------------------

angular
  .module('atelier')
  .filter('format', format)

format['$inject'] = ['$filter']
function format ($filter) {
  return function (value, pattern) {
    if (!angular.isString(pattern)) pattern = '%n'
    if (!angular.isNumber(value)) return null
    if (isNaN(value)) return null

    if (pattern === '%s') {
      return String(value)
    }

    if (pattern === '%d') {
      const decimal   = String(value).split('.')[1]
      const precision = decimal ? decimal.length : 0

      return $filter('number')(value, precision)
    }

    return pattern.replace(/(%n)(\d+)?/g, function (match, p1, p2) {
      return $filter('number')(value, p2)
    })
  }
}
