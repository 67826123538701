L.Control.Sidebar = L.Control.extend({
  options: {
    position: 'topright'
  },
  onAdd:         onAdd,
  onRemove:      onRemove,
  openSidebar:   openSidebar,
  closeSidebar:  closeSidebar,
  toggleSidebar: toggleSidebar
})

L.control.sidebar = function (options) {
  return new L.Control.Sidebar(options)
}

function onAdd (map) {
  const container = L.DomUtil.create('div', 'leaflet-control-sidebar leaflet-bar')

  // -- Button
  const button = L.DomUtil.create('a', 'leaflet-control-sidebar-toggle', container)

  button.href  = '#'
  button.title = 'Afficher le menu des couches à afficher'

  button.setAttribute('role', 'button')
  button.setAttribute('aria-label', button.title)

  L.DomEvent.disableClickPropagation(button)
  L.DomEvent.disableContextMenuPropagation(button)
  L.DomEvent.on(button, 'click', L.DomEvent.stop)
  L.DomEvent.on(button, 'click', toggleSidebar, this)

  // -- Sidebar
  const sidebar = L.DomUtil.create('div', 'leaflet-sidebar', map._container)

  if (L.Browser.touch) L.DomUtil.addClass(sidebar, 'leaflet-touch')

  L.DomEvent.disableClickPropagation(sidebar)
  L.DomEvent.disableScrollPropagation(sidebar)
  L.DomEvent.disableContextMenuPropagation(sidebar)

  // If we need to handle sidebar position on left/right
  // if (this.options.position.index('left') > 1) {
  //   L.DomUtil.addClass(sidebar, 'leaflet-sidebar-left')
  // } else {
  //   L.DomUtil.addClass(sidebar, 'leaflet-sidebar-right')
  // }

  // -- Saved elements
  this._container = container
  this._sidebar   = sidebar
  this._scope     = L.Angular.compile(sidebar, '<leaflet-sidebar>')

  return container
}

function onRemove (map) {
  L.DomUtil.remove(this._sidebar)
  if (this._scope) this._scope.$destroy()
}

function toggleSidebar () {
  this._opened ? this.closeSidebar() : this.openSidebar()
}

function openSidebar () {
  this._opened = true
  L.DomUtil.addClass(this._map._container, 'leaflet-container-sidebar-opened')
}

function closeSidebar () {
  this._opened = false
  L.DomUtil.removeClass(this._map._container, 'leaflet-container-sidebar-opened')
}
