/**
 * Allows the selection of multiple polygons
 * and make meta+click event available to other module and components
 */
angular
  .module('atelier.GIS')
  .factory('LeafletModules.Selection', Selection)

Selection['$inject'] = []
function Selection () {
  return function () {
    const mod  = this
    let leaflet, service, enabled

    // Hooks
    mod.$onInit    = onInit
    mod.$onDestroy = onDestroy

    // Functions
    mod.enable     = enable
    mod.disable    = disable

    // Hooks
    // -----------------------------------------------------------------------
    function onInit () {
      leaflet = mod.$leaflet
      service = mod.$service
      enabled = true

      leaflet.map.on('click', onClick)
      leaflet.map.on('metaClick', onMetaClick)
    }

    function onDestroy () {
      leaflet.map.off('click', onClick)
      leaflet.map.off('metaClick', onMetaClick)
    }

    // Public functions
    // -----------------------------------------------------------------------
    function enable () {
      enabled = true
    }

    function disable () {
      enabled = false
      leaflet.polygonsSelection.stop()
    }

    // Private functions
    // -----------------------------------------------------------------------
    // meta+click is now catchable through by any other module or component
    function onClick (event) {
      const metaClick = event.originalEvent[L.Browser.win ? 'ctrlKey' : 'metaKey']

      if (metaClick) {
        event = angular.extend(event, { type: 'metaClick' })
        leaflet.map.fire('metaClick', event)
      }
    }

    function onMetaClick (event) {
      if (!enabled) return

      const options = service.options
      if (!options.allowPolygonSelection) return
      if (event.sourceTarget === leaflet.map) return

      const selection      = leaflet.polygonsSelection
      const alreadyStarted = selection.hasLayers()

      selection.start()
      selection.toggle(event.sourceTarget)

      if (!alreadyStarted && options.onSelectionStart) {
        options.onSelectionStart()
      }
    }
  }
}
