angular
  .module('atelier.confirm', [
    'material.components.dialog'
  ])
  .factory('confirm', confirmFactory)

confirmFactory['$inject'] = ['$mdDialog']
function confirmFactory ($mdDialog) {
  const confirmTemplate = `
    <md-dialog aria-label="{{$ctrl.title}}">
      <md-dialog-content class="md-dialog-content">
        <h2 class="md-title" ng-if="$ctrl.title">{{$ctrl.title}}</h2>
        <div ng-bind-html="$ctrl.content"></div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" ng-click="$ctrl.hide()">{{$ctrl.submitText}}</md-button>
        <md-button ng-click="$ctrl.cancel()">{{$ctrl.cancelText}}</md-button>
      </md-dialog-actions>
    </md-dialog>
  `

  return function (title, content, event, options) {
    options = angular.extend({}, options)

    return $mdDialog.show({
      targetEvent:         event,
      multiple:            true,
      clickOutsideToClose: false,
      template:            confirmTemplate,
      controllerAs:        '$ctrl',
      controller:          function () {
        const ctrl = this

        ctrl.title      = title
        ctrl.content    = content
        ctrl.submitText = options.submitText || 'Continuer'
        ctrl.cancelText = options.cancelText || 'Annuler'
        ctrl.hide       = $mdDialog.hide
        ctrl.cancel     = $mdDialog.cancel
      }
    })
  }
}
