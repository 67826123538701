angular
  .module('atelier.uploaders')
  .component('fileUploader', {
    controller: Controller,
    template:   require('./file_uploader.template.pug')(),
    transclude: {
      hint: '?fileUploaderHint'
    },
    bindings: {
      url:      '@',
      file:     '=?',
      params:   '<',
      onAdd:    '&',
      onRemove: '&'
    }
  })

Controller['$inject'] = ['$element', '$timeout', '$q', '$mdDialog', 'FileUploader', 'CSRF']
function Controller ($element, $timeout, $q, $mdDialog, FileUploader, CSRF) {
  const ctrl = this

  ctrl.$onInit = onInit
  ctrl.add     = add
  ctrl.remove  = remove

  // Hook
  // -----------------------------------------------------------------------
  function onInit () {
    ctrl.uploader = new FileUploader({
      url:               ctrl.url,
      autoUpload:        false,
      formData:          [CSRF, ctrl.params],
      onAfterAddingFile: onAfterAddingFile,
      onSuccessItem:     onSuccessItem
    })
  }

  // Public functions
  // -----------------------------------------------------------------------
  function add () {
    if (ctrl.clickTriggered) return

    $timeout(function () {
      ctrl.clickTriggered = true
      $element.find('input[type="file"]').click()
      ctrl.clickTriggered = false
    })
  }

  function remove () {
    const file = ctrl.file
    if (file && file.id) {
      file._destroy = true
    } else {
      ctrl.file = null
    }

    ctrl.onRemove({ $file: file })
  }

  // Private functions
  // -----------------------------------------------------------------------
  function onAfterAddingFile (item) {
    const file = {
      uploading: true,
      item:      item,
      filename:  item.file.name
    }

    item.record = file
    ctrl.file   = file
    item.upload()
  }

  function onSuccessItem (item, response) {
    angular.extend(item.record, response.file, {
      uploading: false,
      response:  response
    })

    ctrl.onAdd({ $file: item.record })
  }
}
