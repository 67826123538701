angular
  .module('atelier')
  .filter('truncate', truncate)

// truncate['$inject'] = []
function truncate () {
  return function (input, length) {
    if (angular.isString(input) && angular.isNumber(length) && input.length > length) {
      return input.slice(0, length - 3) + '...'
    } else {
      return input
    }
  }
}
