export default authRedirection

authRedirection['$inject'] = ['$q', '$location', '$window']
function authRedirection ($q, $location, $window) {
  return {
    responseError: function (rejection) {
      if (!rejection.config.ignoreAuthRedirection) {
        if (rejection.status === 401) {
          // Reload instead of changing location
          // to allow backend to store the required path.
          $window.location.reload()
          return $q.reject('Authorization rejected, reload the page')
        } else if (rejection.status === 403) {
          $location.url('/')
          return $q.reject('Authorization rejected, redirect to homepage')
        }
      }

      return $q.reject(rejection)
    }
  }
}
