angular
  .module('atelier')
  .filter('siren', siren)
  .filter('siret', siren)

// siren['$inject'] = []
function siren () {
  return function (value) {
    if (value && value !== null) {
      const match = String(value).replace(/\s/g, '').match(/^(\d{3})(\d{3})(\w{3})(\w{5})?/)

      if (match) {
        return match.slice(1).join(' ').trim()
      } else {
        return value
      }
    }
  }
}
