L.Control.DrawSession = L.Control.extend({
  options: {
    position:            'topleft',
    selectedPathOptions: {
      dashArray:     '10, 10',
      fill:          true,
      maintainColor: true
    }
  },
  onAdd:         onAdd,
  onRemove:      onRemove,
  addLayer:      addLayer,
  addGeoJson:    addGeoJson,
  reset:         reset,
  _createTool:   _createTool,
  _createButton: _createButton,
  _toggleTool:   _toggleTool
})

L.control.drawSession = function (options) {
  return new L.Control.DrawSession(options)
}

function onAdd (map) {
  L.JSTS.inject()

  const container = L.DomUtil.create('div', 'leaflet-control-drawing-session leaflet-bar')

  this._createTool('selection', 'Ajouter/supprimer des polygones sur la carte', container, _enableSelection, _disableSelection)
  this._createTool('add-polygon', 'Dessin libre : Ajouter à la sélection', container, _enablePolygonAdd, _disablePolygonAdd)
  this._createTool('cut-polygon', 'Dessin libre : Soustraire de la sélection', container, _enablePolygonCut, _disablePolygonCut)
  this._createTool('edit', 'Modifier la selection', container, _enableEdition, _disableEdition)
  this._createTool('delete', 'Supprimer des surfaces', container, _enableDeletion, _disableDeletion)

  L.DomEvent.disableClickPropagation(container)
  L.DomEvent.disableScrollPropagation(container)
  L.DomEvent.disableContextMenuPropagation(container)

  // Define tooltip text inside onAdd, because another tool
  // may redefine different behaviors and so, different tooltip
  L.drawLocal.draw.handlers.polygon.tooltip = {
    start: 'Cliquez pour commencer à dessiner la forme.',
    cont:  'Cliquez pour continuer à dessiner la forme.',
    end:   'Cliquez sur le premier point pour fermer cette forme.'
  }

  L.drawLocal.edit.handlers.edit.tooltip = {
    text: 'Déplacer les poignées pour modifier la forme.'
  }

  L.drawLocal.edit.handlers.remove.tooltip = {
    text: 'Cliquez sur une forme pour la supprimer'
  }

  this._container   = container
  this._selection   = L.polygonsSelection(map, this.options).start()
  this._polygonDraw = new L.Draw.Polygon(map, { repeatMode: true })
  this._edition     = new L.EditToolbar.Edit(map, {
    featureGroup:        this._selection._featureGroup,
    selectedPathOptions: this.options.selectedPathOptions
  })

  this._deletion    = new L.EditToolbar.Delete(map, {
    featureGroup: this._selection._featureGroup
  })

  return container
}

function onRemove (map) {
  if (this._selection) this._selection.stop()
  if (this._currentTool) this._toggleTool(this._currentTool)
}

function addLayer (layer) {
  if (this._selection) this._selection.add(layer)
}

function addGeoJson (json) {
  if (this._selection) this._selection.addGeoJson(json)
}

function reset () {
  if (this._selection) {
    this._selection.stop()
    this._selection.start()
  }
}

// Confiurate tools
// ------------------------------------------------------------------------------
function _createTool (name, title, container, activation, desactivation) {
  const self = this

  self._createButton('', title, 'leaflet-control-tool-' + name, container, function (event) {
    self._toggleTool(event.target, activation, desactivation)
  })
}

// TODO export this function to extend L.Control
function _createButton (html, title, className, container, fn) {
  const link = L.DomUtil.create('a', className, container)
  link.innerHTML = html
  link.href = '#'
  link.title = title

  link.setAttribute('role', 'button')
  link.setAttribute('aria-label', title)

  L.DomEvent.disableClickPropagation(link)
  L.DomEvent.on(link, 'click', L.DomEvent.stop)
  if (fn) L.DomEvent.on(link, 'click', fn, this)
  L.DomEvent.on(link, 'click', this._refocusOnMap, this)

  return link
}

function _toggleTool (button, activation, desactivation) {
  const currentTool = this._currentTool

  if (currentTool) {
    L.DomUtil.removeClass(currentTool, 'active')
    this._currentTool = null
    this._currentToolDesactivation(this)
  }

  if (currentTool !== button) {
    L.DomUtil.addClass(button, 'active')
    this._currentTool = button
    this._currentToolDesactivation = desactivation
    activation.call(this)
  }
}

// Selection
// ------------------------------------------------------------------------------
function _enableSelection () {
  this._selection.bringToBack()
  this._map.on('click', _onLayerClickedToBeSelected, this)
}

function _disableSelection () {
  this._map.off('click', _onLayerClickedToBeSelected, this)
}

// By default, `click` event are not propagated from underlying layers to the map
// and `event.propagatedFrom` is undefined.
//
// This event can be propagated from layer to map if
// the following function has been called on layer :
//
//    L.DomEvent.allowEventPropagationToMap(layer, 'click')
//
function _onLayerClickedToBeSelected (event) {
  const polygon = event.propagatedFrom
  if (!polygon) return
  this._selection.toggle(polygon)
}

// Add polygon with drawing
// ------------------------------------------------------------------------------
function _enablePolygonAdd () {
  this._polygonDraw.enable()
  this._map.on('draw:created', _addPolygon, this)
}

function _disablePolygonAdd () {
  this._polygonDraw.disable()
  this._map.off('draw:created', _addPolygon, this)
}

function _addPolygon (event) {
  this._selection.add(event.layer)
}

// Cut polygon with drawing
// ------------------------------------------------------------------------------
function _enablePolygonCut () {
  this._polygonDraw.enable()
  this._map.on('draw:created', _cutPolygon, this)
}

function _disablePolygonCut () {
  this._polygonDraw.disable()
  this._map.off('draw:created', _cutPolygon, this)
}

function _cutPolygon (event) {
  this._selection.remove(event.layer)
}

// Edit the selection
// ------------------------------------------------------------------------------
function _enableEdition () {
  this._selection.bringToFront()
  this._edition.enable()
  this._map.on('draw:editvertex', _selectionUpdated, this)
}

function _disableEdition () {
  this._selection.bringToBack()
  this._edition.disable()
  this._map.off('draw:editvertex', _selectionUpdated, this)
}

function _selectionUpdated () {
  this._selection.fire('update')
}

// Delete polygons
// ------------------------------------------------------------------------------
// L.EditToolbar.Delete doesnt propagate any event on _map on each deletion
// Instead, we need to add listeners on each polygon layer
function _enableDeletion () {
  this._selection.bringToFront()
  this._deletion.enable()
  this._deletion._deletableLayers.eachLayer(_enableLayerDeleteEvent, this)
}

function _disableDeletion () {
  this._selection.bringToBack()
  this._deletion.disable()
  this._deletion._deletableLayers.eachLayer(_disableLayerDelete, this)
}

function _enableLayerDeleteEvent (layer) {
  layer.on('deleted', _selectionUpdated, this)
}

function _disableLayerDelete (layer) {
  layer.off('deleted', _selectionUpdated, this)
}
