L.DomEvent.disableContextMenuPropagation = function (element) {
  L.DomEvent.on(element, 'contextmenu', L.DomEvent.stopPropagation)
  return this
}

// Manually propagate the event from a layer to the map
L.DomEvent.allowEventPropagationToMap = function (layer, type) {
  layer.on(type, function (event) {
    L.DomEvent.propagateEventToMap(event)
  })
}

L.DomEvent.propagateEventToMap = function (event) {
  L.DomEvent.stopPropagation(event)

  event.target._map.fire(event.type, L.Util.extend({
    layer:          event.target,
    propagatedFrom: event.target
  }, event), true)
}
