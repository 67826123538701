/**
 * Fetches and displays a street preview image via google maps
 */
angular
  .module('atelier.GIS')
  .component('streetPreview', {
    controller: Controller,
    template:   require('./street_preview.template.pug')(),
    bindings:   {
      latlng: '<'
    },
    require: {
      leafletCtrl: '^^leaflet'
    }
  })

Controller['$inject'] = ['GIS', 'thirdParty']
function Controller (GIS, thirdParty) {
  const ctrl = this

  // Bindable members
  // -------------------------------------------------------------------------
  ctrl.$onInit = onInit
  ctrl.open    = open

  // Component hooks
  // -------------------------------------------------------------------------
  function onInit () {
    ctrl.leafletCtrl.service
      .checkProvider('google')
      .then(function () {
        return thirdParty.inject('googleMaps')
      })
      .then(getImageUrl, angular.noop)
      .then(function () {
        ctrl.ready = true
      })
  }

  // Public functions
  // -------------------------------------------------------------------------
  function open () {
    ctrl.leafletCtrl.service.set('streetViewVisible', true)
    ctrl.leafletCtrl.service.requestStreetView(ctrl.latlng)
  }

  // Private functions
  // -------------------------------------------------------------------------
  function getImageUrl () {
    return GIS.StreetView.getStaticUrl(ctrl.latlng, [298, 68]).then(function (url) {
      ctrl.imageUrl = url
    })
  }
}
