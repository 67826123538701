angular
  .module('atelier')
  .config(sceConfig)

sceConfig['$inject'] = ['$sceDelegateProvider']
function sceConfig ($sceDelegateProvider) {
  const allowList = ['self']

  if (angular.isDefined(process.env.RAILS_ASSET_HOST)) {
    allowList.push(process.env.RAILS_ASSET_HOST + '/**')
  }

  $sceDelegateProvider.trustedResourceUrlList(allowList)
}
