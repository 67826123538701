angular
  .module('atelier.resources')
  .factory('resourceCache', resourceCache)

resourceCache['$inject'] = ['$cacheFactory']
function resourceCache ($cacheFactory) {
  return {
    http: $cacheFactory.get('$http')
  }
}
