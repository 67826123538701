angular
  .module('atelier.editable')
  .directive('editable', function () {
    return {
      restrict:   'A',
      controller: EditableController
    }
  })

EditableController['$inject'] = ['$element', '$mdPanel']
function EditableController ($element, $mdPanel) {
  const ctrl  = this
  const panel = $element.find('editable-panel')
  let position, panelRef

  ctrl.close = close
  ctrl.open  = open

  $element
    .attr('tabindex', '0')
    .attr('role', 'button')
    .attr('aria-expanded', 'false')
    .on('click', open)

  // Functions
  // --------------------------------------------------------------------------------
  function open () {
    $element.attr('aria-expanded', 'true')

    position = $mdPanel
      .newPanelPosition()
      .relativeTo($element)
      .addPanelPosition(
        $mdPanel.xPosition.ALIGN_START,
        $mdPanel.yPosition.ALIGN_TOPS
      )

    $mdPanel.open({
      attachTo:            'body',
      contentElement:      panel,
      clickOutsideToClose: true,
      escapeToClose:       true,
      position:            position,
      onDomAdded:          onDomAdded,
      onOpenComplete:      onOpenComplete,
      onDomRemoved:        onDomRemoved
    })
  }

  function close () {
    panelRef.close()
  }

  function onDomAdded (results) {
    panelRef = results[1]
    if (angular.isFunction(ctrl.onOpen)) ctrl.onOpen()
  }

  function onOpenComplete () {
    const alignTarget = panel.find('[editable-align-target]')[0] || panel.find('ng-transclude')[0]
    const alignOrigin = $element.find('[editable-align-origin]')[0] || $element[0]

    let offset  = $element.attr('offset')
    let offsetX = 0
    let offsetY = 0

    if (angular.isString(offset)) {
      offset  = offset.split(' ')
      offsetX = parseInt(offset[0])
      offsetY = parseInt(offset[1])
    }

    alignTarget.style.width = alignOrigin.offsetWidth + 'px'

    const panelRect       = panel[0].getBoundingClientRect()
    const originRect      = alignOrigin.getBoundingClientRect()
    const alignTargetRect = alignTarget.getBoundingClientRect()

    offsetX += panelRect.left - alignTargetRect.left
    offsetY += panelRect.top - alignTargetRect.top

    position = $mdPanel
      .newPanelPosition()
      .absolute()
      .top(originRect.top + offsetY + 'px')
      .left(originRect.left + offsetX + 'px')

    panelRef.updatePosition(position)
  }

  function onDomRemoved () {
    $element.attr('aria-expanded', 'false')
    if (angular.isFunction(ctrl.onClose)) ctrl.onClose()
  }
}
