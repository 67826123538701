angular
  .module('atelier')
  .filter('surface', surface)

surface['$inject'] = ['$filter']
function surface ($filter) {
  return function (value) {
    return $filter('format')(value, '%n m²')
  }
}
