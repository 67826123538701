angular.module('atelier', [
  // Third parties
  'ngAnimate',
  'ngAria',
  'ngLocale',
  'ngMaterial',
  'ngMessages',
  'ngMessageFormat',
  'ngRoute',
  'ngSanitize',
  'angularFileUpload',
  'rails',
  'txx.diacritics',

  // Legacy sprockets applications
  'app',
  'app.atelier',

  // Singleton modules
  'atelier.announcement',
  'atelier.autocomplete',
  'atelier.config',
  'atelier.confirm',
  'atelier.dialogComponent',
  'atelier.editable',
  'atelier.expandable',
  'atelier.explore',
  'atelier.GIS',
  'atelier.inputs',
  'atelier.jobWatcher',
  'atelier.labelize',
  'atelier.loadingBar',
  'atelier.loadingButtons',
  'atelier.locationURL',
  'atelier.navbar',
  'atelier.numericBadge',
  'atelier.objectPath',
  'atelier.railsResourceForm',
  'atelier.resources',
  'atelier.selectAllCheckbox',
  'atelier.sidebar',
  'atelier.thirdParty',
  'atelier.uploaders'
])

require('./modules/announcement')
require('./modules/autocomplete')
require('./modules/config')
require('./modules/confirm')
require('./modules/dialog-component')
require('./modules/editable')
require('./modules/expandable')
require('./modules/explore')
require('./modules/gis')
require('./modules/inputs')
require('./modules/job-watcher')
require('./modules/labelize')
require('./modules/loading-bar')
require('./modules/loading-buttons')
require('./modules/location-url')
require('./modules/navbar')
require('./modules/numeric-badge')
require('./modules/object-path')
require('./modules/rails-resource-form')
require('./modules/resources')
require('./modules/select-all-checkbox')
require('./modules/sidebar')
require('./modules/third-party')
require('./modules/uploaders')

require('./config/angular.js')
require('./config/http.config.js')
require('./config/locale.config.js')
require('./config/sce.config.js')
require('./config/material/date_locale.config.js')
require('./config/material/theming.config.js')

require('./core/filters.js')
