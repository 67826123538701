angular
  .module('atelier.navbar')
  .factory('navbarCurrent', navbarCurrent)

navbarCurrent['$inject'] = ['$window']
function navbarCurrent ($window) {
  return function (href) {
    if (!href || angular.isUndefined(href)) return

    const parts    = href.split(/(?=#)/g)
    const pathname = parts[0]
    const hash     = angular.isUndefined(parts[1]) ? null : parts[1]

    return $window.location.pathname === pathname && (!hash || $window.location.hash.startsWith(hash))
  }
}
