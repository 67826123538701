require('./L.JSTS')

L.PolygonsSelection = L.Evented.extend({
  options: {
    pane:  'selectorPane',
    style: {
      weight:      1,
      fillOpacity: 0.3,
      color:       '#ce2114'
    }
  },
  initialize:          initialize,
  start:               start,
  stop:                stop,
  toGeoJSON:           toGeoJSON,
  addGeoJson:          addGeoJson,
  add:                 add,
  remove:              remove,
  toggle:              toggle,
  bringToFront:        bringToFront,
  bringToBack:         bringToBack,
  hasLayers:           hasLayers,
  _add:                _add,
  _remove:             _remove,
  _updateFeatureGroup: _updateFeatureGroup,
  _addToFeatureGroup:  _addToFeatureGroup
})

L.polygonsSelection = function (map, options) {
  return new L.PolygonsSelection(map, options)
}

// ---------------------------------------------------------------------------
function initialize (map, options) {
  L.JSTS.inject()
  L.setOptions(this, options)

  let pane = this.options.pane
  pane = map.getPane(pane) || map.createPane(pane)
  pane.style.zIndex = 349

  this._map  = map
  this._featureGroup = new L.FeatureGroup([], this.options)
}

function start () {
  if (this._active) return this

  this._featureGroup.addTo(this._map)
  this._active = true

  return this
}

function stop () {
  this._featureGroup.clearLayers()
  this._featureGroup.remove()
  this._active = false

  return this
}

function bringToFront () {
  this._map.getPane(this.options.pane).style.zIndex = 450
}

function bringToBack () {
  this._map.getPane(this.options.pane).style.zIndex = 349
}

function toGeoJSON (precision) {
  if (this.hasLayers()) {
    return this._featureGroup.toGeoJSON(precision)
  } else {
    return null
  }
}

function addGeoJson (json) {
  this._add(L.geoJson(json, this.options))
  this.fire('update')
  return this
}

function add (layer) {
  this._add(layer)
  this.fire('update')
  return this
}

function remove (layer) {
  if (!this.hasLayers()) return this

  this._remove(layer)
  this.fire('update')
  return this
}

function toggle (layer) {
  // JSTS methods like covers or contains may not be accurate.
  // To determine if the layer is part of the feature group, we use a ratio
  let ratio

  if (this.hasLayers()) {
    ratio = L.JSTS.coveredRatio(this._featureGroup, layer)
  }

  if (ratio && ratio > 0.9) {
    return this.remove(layer)
  } else {
    return this.add(layer)
  }
}

function hasLayers () {
  return this._featureGroup.getLayers().length > 0
}

function _add (layer) {
  // When adding a layer to an existing set
  // let's proceed to an union to avoid overlapping

  if (this.hasLayers()) {
    const result = L.JSTS.union(this._featureGroup, layer, this.options)
    this._updateFeatureGroup(result)
  } else {
    // If the layer is already part of the map
    // Let's create a copy using GeoJSON
    if (this._map.hasLayer(layer)) {
      layer = L.geoJson(layer.toGeoJSON(false), this.options)
    }

    this._addToFeatureGroup(layer)
  }
}

function _remove (layer) {
  if (!this.hasLayers()) return

  const result = L.JSTS.difference(this._featureGroup, layer, this.options)

  if (result) {
    this._updateFeatureGroup(result)
  } else {
    this._featureGroup.clearLayers()
  }
}

function _updateFeatureGroup (layer) {
  this._featureGroup.clearLayers()
  this._addToFeatureGroup(layer)
}

function _addToFeatureGroup (layer) {
  if (layer.eachLayer) {
    layer.eachLayer(_addToFeatureGroup, this)
  } else {
    layer.setStyle(this.options.style)
    this._featureGroup.addLayer(layer)
  }
}
