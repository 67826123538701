require('leaflet')
require('leaflet-draw')
require('leaflet.markercluster')

angular.module('atelier.GIS', [])

// Services
require('./gis.factory.js')
require('./services/config.constant.js')
require('./services/factory.service.js')
require('./services/streetview.service.js')

// Components
require('./components/oalley/oalley.component.js')
require('./components/location_info/location_info.component.js')
require('./components/sidebar/sidebar.component.js')
require('./components/measure/measure.component.js')
require('./components/preview/street_preview.component.js')
require('./components/street_view/street_view.component.js')

// Modules
require('./leaflet_modules.factory.js')
require('./modules/basemaps.factory.js')
require('./modules/bounds.factory.js')
require('./modules/current_marker.factory.js')
require('./modules/markers.factory.js')
require('./modules/overlays.factory.js')
require('./modules/pegman.factory.js')
require('./modules/photo.factory.js')
require('./modules/selection.factory.js')

require('./handle_gesture.factory.js')
require('./leaflet.component.js')
