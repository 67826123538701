/**
 * Fetches information about a geopoint and displays it
 * ctrl.leafletCtrl.oneBox.open('<location-info latlng=latlng>', options)
 */
angular
  .module('atelier.GIS')
  .component('locationInfo', {
    controller: InformationsController,
    template:   require('./location_info.template.pug')(),
    bindings:   {
      latlng: '<'
    },
    require: {
      leafletCtrl: '^^leaflet'
    }
  })

InformationsController['$inject'] = ['$http']
function InformationsController ($http) {
  const ctrl = this

  ctrl.$onInit = onInit

  // Hooks
  // -------------------------------------------------------------------------
  function onInit () {
    const url = '/api/geo/points/' + ctrl.latlng.lat + '/' + ctrl.latlng.lng

    return $http.get(url, {
      cache: true
    }).then(function (response) {
      ctrl.point = response.data.point
      ctrl.ready = true
    })
  }
}
