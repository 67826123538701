angular
  .module('atelier.jobWatcher', [])
  .factory('JobWatcher', JobWatcher)

JobWatcher['$inject'] = ['$http', '$q', '$timeout', '$cacheFactory']
function JobWatcher ($http, $q, $timeout, $cacheFactory) {
  return {
    watch: initWatcher
  }

  // ---------------------------------------------------------------------------

  function initWatcher (payload, callbackFn) {
    const cache   = $cacheFactory.get('JobWatcher') || $cacheFactory('JobWatcher')
    const cacheId = JSON.stringify(payload)
    let promise = cache.get(cacheId)

    if (!promise) {
      promise = initPromise(payload)
      cache.put(cacheId, promise)
    }

    if (angular.isFunction(callbackFn)) {
      callbackFn({ status: 'queued' })
      promise = promise.then(callbackFn, callbackFn, callbackFn)
    }

    return promise
  }

  function initPromise (payload) {
    const deferred = $q.defer()
    const promise  = deferred.promise

    poll(payload, deferred)
    return promise
  }

  function poll (payload, deferred) {
    $http.get('/api/jobs', {
      params:           payload,
      ignoreLoadingBar: true
    }).then((response) => {
      if (response.data.status === 'completed') {
        deferred.resolve(response.data)
      } else if (response.data.status === 'failed') {
        deferred.reject(response.data)
      } else {
        deferred.notify(response.data)
        $timeout(poll, 2000, true, payload, deferred)
      }
    })
  }
}
