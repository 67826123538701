angular
  .module('atelier.selectAllCheckbox')
  .component('selectAllCheckbox', {
    controller: Controller,
    transclude: true,
    template:   require('./select_all_checkbox.template.pug')(),
    bindings:   {
      options:   '<',
      threshold: '<',
      transform: '&'
    },
    require: {
      ngModelCtrl: '^^ngModel'
    }
  })

Controller['$inject'] = ['$element', '$timeout']
function Controller ($element, $timeout) {
  const ctrl = this

  // Bindable members
  // -------------------------------------------------------------------------
  ctrl.$onInit          = onInit
  ctrl.$onChanges       = onChanges

  ctrl.isChecked        = isChecked
  ctrl.isIndeterminated = isIndeterminated
  ctrl.isDisplayable    = isDisplayable
  ctrl.toggleAll        = toggleAll

  // Component hooks
  // -------------------------------------------------------------------------
  function onInit () {
    const optgroup = $element.parents('md-optgroup').length

    if (!ctrl.threshold && ctrl.threshold !== 0) {
      ctrl.threshold = optgroup ? 0 : 2
    }
  }

  function onChanges (changes) {
    const optionsValues = []

    angular.forEach(ctrl.options, (item, key) => {
      optionsValues.push(ctrl.transform({ $item: item, $key: key }))
    })

    ctrl.optionsValues = optionsValues
  }

  // Public functions
  // -------------------------------------------------------------------------
  function isChecked () {
    return allOptionsAreSelected()
  }

  function isIndeterminated () {
    return oneOptionIsSelected() && !allOptionsAreSelected()
  }

  function isDisplayable () {
    return optionsLength() >= ctrl.threshold
  }

  function toggleAll () {
    let newValues = angular.copy(ctrl.ngModelCtrl.$modelValue || [])

    if (oneOptionIsSelected()) {
      newValues = newValues.filter((el) => !ctrl.optionsValues.includes(el))
    } else {
      newValues = newValues.concat(ctrl.optionsValues)
    }

    $timeout(() => { ctrl.ngModelCtrl.$setViewValue(newValues) })
  }

  // Private functions
  // -------------------------------------------------------------------------
  function optionsLength () {
    if (angular.isArray(ctrl.options)) {
      return ctrl.options.length
    } else if (angular.isObject(ctrl.options)) {
      return Object.keys(ctrl.options).length
    } else {
      return 0
    }
  }

  function modelValueIsBlank () {
    return !(angular.isArray(ctrl.ngModelCtrl.$modelValue) && ctrl.ngModelCtrl.$modelValue.length)
  }

  function modelValueIncludes (value) {
    return ctrl.ngModelCtrl.$modelValue.includes(value)
  }

  function allOptionsAreSelected () {
    if (modelValueIsBlank()) { return false }

    return ctrl.optionsValues.every(modelValueIncludes)
  }

  function oneOptionIsSelected () {
    if (modelValueIsBlank()) { return false }

    return ctrl.optionsValues.some(modelValueIncludes)
  }
}
