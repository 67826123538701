// This is a reviewed version of angular-loading-bar
// The library is not more maintained.
// This review fixes few bugs and remove unneeded stuff
//
// - https://github.com/chieffancypants/angular-loading-bar/pull/50
// - https://github.com/chieffancypants/angular-loading-bar/pull/297

angular
  .module('atelier.loadingBar', [])
  .factory('loadingBarInterceptor', loadingBarInterceptor)
  .factory('loadingBar', loadingBar)

loadingBarInterceptor['$inject'] = ['$q', '$cacheFactory', '$timeout', '$injector', 'loadingBar']
function loadingBarInterceptor ($q, $cacheFactory, $timeout, $injector, loadingBar) {
  // latencyThreshold:  The amount of time spent fetching before showing the loading bar
  // reqsTotal:         The total number of requests made
  // reqsCompleted:     The number of requests completed (either successfully or not)
  // startTimeout:      $timeout handle for latencyThreshold
  const latencyThreshold = 100
  let reqsTotal     = 0
  let reqsCompleted = 0
  let startTimeout

  return {
    request: function (config) {
      beforeRequest(config)
      return config
    },
    response: function (response) {
      if (response) afterRequest(response.config)
      return response
    },
    responseError: function (rejection) {
      if (rejection) afterRequest(rejection.config)
      return $q.reject(rejection)
    }
  }

  // Private functions
  // ---------------------------------------------------------------------------
  function beforeRequest (config) {
    if (!isProcessed(config)) return

    if (reqsTotal === 0) {
      startTimeout = $timeout(loadingBar.start, latencyThreshold)
    }

    reqsTotal++
    loadingBar.set(reqsCompleted / reqsTotal)
  }

  function afterRequest (config) {
    if (!isProcessed(config)) return

    reqsCompleted++
    if (reqsCompleted >= reqsTotal) {
      $timeout.cancel(startTimeout)
      loadingBar.complete()
      reqsCompleted = 0
      reqsTotal     = 0
    } else {
      loadingBar.set(reqsCompleted / reqsTotal)
    }
  }

  function isProcessed (config) {
    return config && !config.ignoreLoadingBar && !isCached(config)
  }

  function isCached (config) {
    let cache
    const defaultCache = $cacheFactory.get('$http')
    const defaults     = $injector.get('$http').defaults
    const url          = buildUrl(config.url, config.paramSerializer(config.params))

    // Choose the proper cache source. Borrowed from angular: $http service
    if (
      (config.cache || defaults.cache) && config.cache !== false &&
      (config.method === 'GET' || config.method === 'JSONP')
    ) {
      if (angular.isObject(config.cache)) {
        cache = config.cache
      } else if (angular.isObject(defaults.cache)) {
        cache = defaults.cache
      } else {
        cache = defaultCache
      }
    }

    const cached = cache !== undefined ? cache.get(url) !== undefined : false
    if (config.cached !== undefined && cached !== config.cached) {
      return config.cached
    }

    config.cached = cached
    return cached
  }

  function buildUrl (url, serializedParams) {
    if (serializedParams.length > 0) {
      url += ((url.indexOf('?') === -1) ? '?' : '&') + serializedParams
    }
    return url
  }
}

loadingBar['$inject'] = ['$injector', '$document', '$timeout']
function loadingBar ($injector, $document, $timeout) {
  const autoIncrement       = true
  const startSize           = 0.02
  const parentSelector      = 'body'
  const loadingBarContainer = angular.element('<div id="loading-bar"><div class="bar"><div class="peg"></div></div></div>')
  const loadingBar          = loadingBarContainer.find('div').eq(0)

  let started = false
  let status  = 0
  let incrementTimeout, completeTimeout

  // $animate is injected manually to avoid circular dependency:
  // $templateRequest <- $$animateQueue <- $animate <- loadingBar <- $http <- $templateRequest <- $compile
  let $animate

  return {
    start:     start,
    set:       set,
    status:    getStatus,
    increment: increment,
    complete:  complete
  }

  function start () {
    const parent = $document.find(parentSelector).eq(0)
    $timeout.cancel(completeTimeout)

    // do not continually broadcast the started event:
    if (started) return

    started = true

    if (!$animate) $animate = $injector.get('$animate')
    $animate.enter(loadingBarContainer, parent, angular.element(parent[0].lastChild))

    set(startSize)
  }

  function getStatus () {
    return status
  }

  function set (value) {
    if (!started) return

    status = value
    loadingBar.css('width', (value * 100) + '%')

    // increment loadingbar to give the illusion that there is always
    // progress but make sure to cancel the previous timeouts so we don't
    // have multiple incs running at the same time.
    if (autoIncrement) {
      $timeout.cancel(incrementTimeout)
      incrementTimeout = $timeout(increment, 250)
    }
  }

  function increment () {
    if (status >= 1) return

    let rnd = 0

    if (status >= 0 && status < 0.25) {
      // Start out between 3 - 6% increments
      rnd = (Math.random() * (5 - 3 + 1) + 3) / 100
    } else if (status >= 0.25 && status < 0.65) {
      // increment between 0 - 3%
      rnd = (Math.random() * 3) / 100
    } else if (status >= 0.65 && status < 0.9) {
      // increment between 0 - 2%
      rnd = (Math.random() * 2) / 100
    } else if (status >= 0.9 && status < 0.99) {
      // finally, increment it .5 %
      rnd = 0.005
    } else {
      // after 99%, don't increment:
      rnd = 0
    }

    set(status + rnd)
  }

  function complete () {
    set(1)
    $timeout.cancel(completeTimeout)

    // Attempt to aggregate any start/complete calls within 500ms:
    completeTimeout = $timeout(function () {
      if (!$animate) $animate = $injector.get('$animate')
      const promise = $animate.leave(loadingBarContainer, completeAnimation)
      if (promise && promise.then) promise.then(completeAnimation)
    }, 500)
  }

  function completeAnimation () {
    status  = 0
    started = false
  }
}
