angular
  .module('atelier.announcement')
  .component('announcement', {
    controller: AnnouncementController,
    template:   require('./announcement.template.pug')(),
    bindings:   {
      announces: '<'
    }
  })

AnnouncementController['$inject'] = ['$http', '$mdDialog']
function AnnouncementController ($http, $mdDialog) {
  const ctrl = this

  ctrl.$onInit  = onInit
  ctrl.show     = show
  ctrl.showNext = showNext
  ctrl.dismiss  = dismiss

  // Hooks
  // -------------------------------------------------------------------------
  function onInit () {
    show(0)
  }

  // Public functions
  // -------------------------------------------------------------------------
  function showNext () {
    show(ctrl.currentIndex + 1)
  }

  function show (index) {
    const announce = ctrl.announces[index]
    if (!announce) return

    ctrl.currentIndex    = index
    ctrl.currentAnnounce = announce
  }

  function dismiss () {
    ctrl.announces.forEach(function (announce) {
      $http.post('/d/announces/' + announce.id + '/dismiss', { ignoreLoadingBar: true })
    })

    $mdDialog.hide()
  }
}
