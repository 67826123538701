require('./L.Angular')

L.GeoJSON.HTTP = L.GeoJSON.extend({
  initialize: initialize,
  onAdd:      onAdd,
  update:     update
})

L.geoJson.http = function (url, options) {
  return new L.GeoJSON.HTTP(url, options)
}

function initialize (url, options) {
  L.GeoJSON.prototype.initialize.call(this, [], options)
  this._url = url
}

function onAdd (map) {
  L.GeoJSON.prototype.onAdd.call(this, map)
  this.update()
}

function update () {
  const self = this

  return L.Angular.get('$http').get(self._url, {
    cache: true
  }).then(function (results) {
    self.clearLayers()
    self.addData(results.data.features)
  })
}
