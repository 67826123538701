angular
  .module('atelier')
  .filter('numberWithSign', numberWithSign)

numberWithSign['$inject'] = ['$filter']
function numberWithSign ($filter) {
  return function (value, pattern) {
    value = $filter('format')(value, pattern)

    if (angular.isString(value)) {
      value = value.replace(/(-)?((\d+[ .,]?)+)/, function (m, p1, p2) {
        return (p1 ? '-' : '+') + ' ' + p2
      })
    }

    return value
  }
}
