angular
  .module('atelier.autocomplete')
  .service('Autocompletion', service)

service['$inject'] = ['$http']
function service ($http) {
  return class Autocompletion {
    constructor (url, params) {
      this.url          = url
      this.scopedParams = params
    }

    scope (params) {
      this.scopedParams = params
    }

    items () {
      const url     = this.url
      const params  = angular.extend(
        {},
        this.scopedParams,
        this.searchText ? { q: this.searchText } : null
      )

      return $http.get(url, {
        params: params
      }).then(function (response) {
        return response.data.results
      })
    }
  }
}
