angular
  .module('atelier.GIS')
  .constant('GIS.Config', {
    cacheStore:     'defaultGisCache',
    defaultBasemap: 'roadmap',

    // Define which providers are available
    // It'll be crossed with providers returned by promise resolution
    providers: [
      'esri',
      'google',
      'osm'
    ],

    // Define which basemaps are available
    // It'll be crossed with basemaps returned by promise resolution
    // If promise doesn't define basemaps, all basemaps will be rendered (depending on providers)
    basemaps: [
      'esri.roadmap',
      'esri.street',
      'esri.satellite',
      'google.roadmap',
      'google.satellite',
      'google.plain',
      'osm',
      'arcgis',
      'wms'
    ],

    // Define which overlays are available
    // It'll be crossed with overlays returned by promise resolution
    // If undefined, all the overlays returned by the promise are available
    // overlays: ['collectivite', 'sections', 'parcelles']

    // Define which overlays are selected by default
    // If present, it'll override defaultOverlays returned by the promise
    // If undefined, defaultOverlays returned by the promise are used
    // defaultOverlays: ['collectivite', 'sections', 'parcelles']

    // Leaflet config
    // ------------------------------------------------------------------------------------------
    // Options for L.Map
    leafletMap: {
      zoomControl:        false,
      minZoom:            6,
      maxZoom:            21,
      doubleClickZoom:    true,
      attributionControl: false
    },

    // Controls
    // ------------------------------------------------------------------------------------------
    // Show/Hide all controls
    showControls: true,

    // Options for L.Control.Sidebar
    // sidebarControl: {
    //   position: 'topright'
    // }

    // Options for L.Control.Zoombar
    // zoomBarControl: {
    //   position: 'topright'
    // }

    // Options for L.Control.Feature
    // featureControl: {
    //   position: 'bottomright',
    //   template: '<div>'
    // }

    // Options for L.Control.DrawControl (disabled by default)
    // drawSessionControl: {
    //   position: 'topleft'
    // }

    // Activate/Desactive gesture handling on Leaflet & StreetView
    // Usefull when the component are inside a scrollbale page (like showcase)
    // handleGesture: false

    // Markers icons
    // ------------------------------------------------------------------------------------------
    // Default icon for any marker
    defaultIcon: {
      className: 'leaflet-marker-default',
      iconSize:  [10, 10],
      html:
        '<div class="leaflet-marker-svg-wrapper">' +
          '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">' +
            '<circle cx="5" cy="5" r="5"/>' +
          '</svg>' +
        '</div>'
    },

    // Some markers may include a `type' :
    //    { lat:  45.47484, lng: 0.3673, type: 'lco' }
    //
    // A CSS class will be concatenated and added to these markers :
    //    hab  -> leaflet-marker-icon-hab
    //    lco  -> leaflet-marker-icon-lco
    //    and so on
    //
    customIcon: {
      className:       'leaflet-marker-custom',
      classTypePrefix: 'leaflet-marker-type-',
      iconSize:        [20, 28],
      iconAnchor:      [10, 28],
      html:
        '<div class="leaflet-marker-svg-wrapper">' +
          '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 100 140">' +
            '<path d="M50,140 C58,115 67,104 74,96 C88.3336978,79.6186311 100,70.506668 100,50 C100,22.3857625 77.6142375,0 50,0 C22.3857625,0 0,22.3857625 0,50 C0,70.4987061 11.6736532,79.6270322 26,96 C33,104 42,115 50,140 Z"/>' +
          '</svg>' +
        '</div>'
    },

    // Icon used as the current marker
    currentIcon: {
      className:  'leaflet-marker-current',
      iconSize:   [26, 41],
      iconAnchor: [13, 41],
      html:
        '<div class="leaflet-marker-svg-wrapper">' +
          '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="41" viewBox="0 0 125 205">' +
            '<path d="M65,205 C88.4,108.370732 130,122.161765 130,65.2573529 C130,27.9115657 100.898509,0 65,0 C29.1014913,0 0,28.7132353 0,65.2573529 C0,122.161765 41.6,108.370732 65,205 Z"/>' +
          '</svg>' +
        '</div>'
    },

    // The pegman, which indicates what we are looking on StreetView
    pegmanIcon: {
      className:  'leaflet-pegman-marker',
      iconSize:   [40, 44],
      iconAnchor: [20, 32]
    },

    // Deepth index of each markers type :
    markersIndex: {
      pegman:  750,
      default: 800,
      focus:   850,
      current: 900
    },

    // Streetview
    // ------------------------------------------------------------------------------------------
    // Allow streetview by passing true, an object or a GIS.StreetView instance
    // This is also conditionned by the promise resolvation.
    streetView: {
      imageDateControl:      true,
      addressControl:        true,
      enableCloseButton:     false,
      fullscreenControl:     false,
      addressControlOptions: {
        position: 3 // google.maps.ControlPosition.TOP_RIGHT
      }
    },

    // TODO: Add a button under the zoombar
    // streetViewControl: false

    // Add a pegman marker, synced with streetView panorama
    // displayPegman: false

    // Other extensions
    // ------------------------------------------------------------------------------------------
    // Open a component menu on right-click
    // contextMenu: null

    // Options for L.OneBox
    // oneBox: {}

    // Options for L.PolygonsSelection
    // polygonsSelection: {
    //   pane:        'selectorPane',
    //   weight:      1,
    //   fillOpacity: 0.2,
    //   color:       'blue'
    // }

    // Options for L.MarkerClusterGroup
    markerClusterGroup: {
      showCoverageOnHover: false,
      spiderfyOnMaxZoom:   false,
      maxClusterRadius:    function (zoom) {
        if (zoom > 17) {
          return 1
        } else if (zoom <= 15) {
          return 60
        } else {
          return { 17: 25, 16: 35, 15: 40 }[zoom]
        }
      }
    },

    // Various Behaviors
    // ------------------------------------------------------------------------------------------
    // Allow to focus on a marker after clicking on it
    // allowFocusOnMarkers: false,

    // Allow to take photos from Google satellite view
    // allowPhotoFromSatellite: false,

    // Allow to take photos from StreetView
    // allowPhotoFromStreetView: false,

    // Allow to select multiple polygons with ctrl+click or meta+click
    // allowPolygonSelection: false,

    // Callback to call when selection starts with ctrl+click or meta+click
    // onSelectionStart: false,

    // Display a pegman when StreetView is visible
    showPegman: true,

    // Define a maximum zoom when reseting bounds
    resetBoundsWithMaxZoom: 18,

    // Reset bounds when markers are updated
    // resetBoundsOnMarkers: false,

    // Reset bounds when focused markers are updated
    // resetBoundsOnFocused: false,

    // When resetting the map view, keep in view the bounds
    // provided through options or promise.
    // It may also be extended to include outside markers.
    // keepDefaultBoundsOnReset: false,

    // Google map styling
    // ------------------------------------------------------------------------------------------
    googleRoadmapStyles: [
      {
        featureType: 'poi',
        stylers:     [{ visibility: 'off' }]
      }
      // {
      //   featureType: 'transit',
      //   stylers: [{ visibility: 'off' }]
      // }
    ],

    googlePlainStyles: [
      {
        featureType: 'landscape',
        stylers:     [{ color: '#ffffff' }]
      },
      {
        featureType: 'landscape',
        elementType: 'labels',
        stylers:     [{ visibility: 'off' }]
      },
      {
        featureType: 'water',
        stylers:     [{ color: '#e2f0ff' }]
      },
      {
        featureType: 'water',
        elementType: 'labels',
        stylers:     [{ visibility: 'off' }]
      },
      {
        featureType: 'administrative',
        stylers:     [{ visibility: 'off' }]
      },
      {
        featureType: 'poi',
        stylers:     [{ visibility: 'off' }]
      },
      {
        featureType: 'road',
        stylers:     [{ visibility: 'off' }]
      },
      {
        featureType: 'transit',
        stylers:     [{ visibility: 'off' }]
      }
    ],

    // Overlays styling
    // ------------------------------------------------------------------------------------------
    layers: {
      collectivite: {
        index: 8,
        style: { weight: 2, fill: false, color: '#E31A1C' }
      },
      departements: {
        index:           7,
        style:           { weight: 2, fillOpacity: 0.02, color: '#bcd400' },
        hover:           { weight: 3, fillOpacity: 0.2 },
        transparentZoom: 14
      },
      epcis: {
        index:           6,
        style:           { weight: 2, fillOpacity: 0.02, color: '#00a843' },
        hover:           { weight: 3, fillOpacity: 0.2 },
        transparentZoom: 14
      },
      cantons: {
        index:           6,
        style:           { weight: 2, fillOpacity: 0.02, color: '#00a843' },
        hover:           { weight: 3, fillOpacity: 0.2 },
        transparentZoom: 14
      },
      communes: {
        index:           5,
        style:           { weight: 2, fillOpacity: 0.02, color: '#0033ff' },
        hover:           { weight: 3, fillOpacity: 0.2 },
        transparentZoom: 14
      },
      irises: {
        index:           5,
        style:           { weight: 2, fillOpacity: 0.02, color: '#0033ff' },
        hover:           { weight: 3, fillOpacity: 0.2 },
        transparentZoom: 14
      },
      zone: {
        index:    10,
        legend:   true,
        colorize: true,
        style:    { weight: 2, fillOpacity: 0.4 },
        hover:    { weight: 2, fillOpacity: 0.5 }
      },
      secteur: {
        index:    1,
        legend:   true,
        colorize: 'fillAndStrokeOnHover',
        style:    { weight: 1, fillOpacity: 0.6, color: '#ffffff' },
        hover:    { weight: 2, fillOpacity: 0.7 }
      },
      coef: {
        index:    2,
        legend:   true,
        colorize: 'fill',
        style:    { weight: 1, fillOpacity: 0.4, color: '#ffffff' },
        hover:    { weight: 2, fillOpacity: 0.5 }
      },
      arcgis: {
        index:    10,
        legend:   true,
        colorize: true,
        style:    { weight: 2, fillOpacity: 0.4 },
        hover:    { weight: 2, fillOpacity: 0.5 }
      },
      geojson: {
        index:    10,
        legend:   true,
        colorize: true,
        style:    { weight: 2, fillOpacity: 0.4 },
        hover:    { weight: 2, fillOpacity: 0.5 }
      },
      sections: {
        index:           15,
        style:           { weight: 1, fillOpacity: 0.0, color: '#5062c2' },
        hover:           { weight: 2, fillOpacity: 0.1 },
        minZoom:         14,
        transparentZoom: 16
      },
      parcelles: {
        index:   16,
        style:   { weight: 1, fillOpacity: 0, color: '#b6cd20' },
        hover:   { weight: 2, fillOpacity: 0.6 },
        minZoom: 16
      },
      unites_foncieres: {
        index:   17,
        style:   { weight: 1, fillOpacity: 0, color: '#cca92a' },
        hover:   { weight: 2, fillOpacity: 0.6 },
        minZoom: 16
      },
      locaux: {
        index:   18,
        style:   { weight: 1, fillOpacity: 0.2, color: '#0A4585' },
        hover:   { weight: 2, fillOpacity: 0.6 },
        minZoom: 16
      },
      surfaces: {
        index:   19,
        style:   { weight: 1, fillOpacity: 0.5, color: '#0049e7', fillColor: '#a5eaff' },
        hover:   { weight: 2, fillOpacity: 0.7 },
        minZoom: 16
      },
      ign: {
        index:       9,
        minZoom:     0,
        maxZoom:     19,
        tileSize:    256,
        attribution: 'IGN-F/Géoportail'
      }
    }
  })
