require('./L.Angular')

L.JSTS = {
  inject:       inject,
  toGeom:       convertLayerToGeometry,
  coveredRatio: coveredRatio,
  getArea:      getArea,
  union:        layerFunction('union'),
  difference:   layerFunction('difference'),
  intersection: layerFunction('intersection'),
  within:       basicFunction('within'),
  contains:     basicFunction('contains'),
  intersects:   basicFunction('intersects'),
  overlaps:     basicFunction('overlaps'),
  covers:       basicFunction('covers')
}

let jsts

// Public functions
// -------------------------------------------------------------------------
function inject () {
  return L.Angular.get('thirdParty').inject('jsts').then(function (result) {
    jsts = result
  })
}

// Example of basic function
//
//    function intersects(layerA, layerB) {
//      var geomA = convertLayerToGeometry(layerA)
//      var geomB = convertLayerToGeometry(layerB)
//
//      return geomA.intersects(geomB)
//    }
//
function basicFunction (name) {
  return function (layerA, layerB) {
    const geomA = convertLayerToGeometry(layerA)
    const geomB = convertLayerToGeometry(layerB)

    return geomA[name](geomB)
  }
}

// Example of layer function
//
//    function union(layerA, layerB, layerOptions) {
//      var geomA  = convertLayerToGeometry(layerA)
//      var geomB  = convertLayerToGeometry(layerB)
//      var result = geomA.union(geomB)
//
//      return convertGeometryToLayer(result, layerOptions)
//    }
//
function layerFunction (name) {
  return function (layerA, layerB, layerOptions) {
    const result = basicFunction(name)(layerA, layerB)
    return convertGeometryToLayer(result, layerOptions)
  }
}

function coveredRatio (layerA, layerB) {
  const geomA = convertLayerToGeometry(layerA)
  const geomB = convertLayerToGeometry(layerB)
  const intersection = geomA.intersection(geomB)

  if (intersection) return intersection.getArea() / geomB.getArea()
}

function getArea (layer) {
  const geom = convertLayerToGeometry(layer)

  return geom.getArea()
}

// Private functions
// -------------------------------------------------------------------------
function convertLayerToGeometry (layer) {
  const factory  = new jsts.geom.GeometryFactory()
  const reader   = new jsts.io.GeoJSONReader(factory)
  const result   = reader.read(layer.toGeoJSON(false))

  // Feature collection to multiPolygon
  if ('features' in result) {
    let geometry = result.features[0].geometry

    for (let i = 1; i < result.features.length; i++) {
      geometry = geometry.union(result.features[i].geometry)
    }

    return geometry

  // Single feature to polygon
  } else if ('geometry' in result) {
    return result.geometry
  }
}

function convertGeometryToLayer (geometry, layerOptions) {
  switch (geometry.getGeometryType()) {
    case 'MultiPolygon': {
      const layers = []

      geometry._geometries.forEach(function (polygon) {
        polygon = convertGeometryToLayer(polygon, layerOptions)
        if (polygon) layers.push(polygon)
      })

      if (!layers.length) return null

      return L.featureGroup(layers, layerOptions) }

    case 'Polygon': {
      const rings = []
      const outerRing = linearRingToLatLngs(geometry._shell)

      if (!outerRing.length) return null
      rings.push(outerRing)

      geometry._holes.forEach(function (hole) {
        rings.push(linearRingToLatLngs(hole))
      })

      return L.polygon(rings, layerOptions) }
  }
}

function linearRingToLatLngs (geometry) {
  return coordinatesToLatLngs(geometry.getCoordinates())
}

function coordinatesToLatLngs (coords) {
  const latlngs = []

  coords.forEach(function (coord) {
    latlngs.push({ lng: coord.x, lat: coord.y })
  })

  return latlngs
}
