L.Control.Feature = L.Control.extend({
  options: {
    position: 'bottomright',
    template:
      '<div class="leaflet-control-feature-content" ng-if="properties.title.length">' +
        '{{ properties.title }}' +
      '</div>' +
      '<div class="leaflet-control-feature-content" ng-if="properties && !properties.title.length">' +
        '<table>' +
          '<tr ng-repeat="(key, label) in properties">' +
            '<th>{{key}}</th>' +
            '<td>{{label}}</td>' +
          '</tr>' +
        '</table>' +
      '</div>'
  },
  onAdd:    onAdd,
  onRemove: onRemove,
  show:     show,
  hide:     hide
})

L.control.feature = function (options) {
  return new L.Control.Feature(options)
}

function onAdd (map) {
  const container = L.DomUtil.create('div', 'leaflet-control-feature leaflet-bar')

  L.DomEvent.disableClickPropagation(container)
  L.DomEvent.disableScrollPropagation(container)
  L.DomEvent.disableContextMenuPropagation(container)

  this._container = container
  this._scope     = L.Angular.compile(this._container, this.options.template, {})

  return container
}

function onRemove (map) {
  if (this._scope) this._scope.$destroy()
}

function show (feature) {
  const scope = this._scope
  if (!scope) return

  scope.$applyAsync(function () {
    angular.extend(scope, {
      feature:    feature,
      properties: feature.properties
    })
  })
}

function hide () {
  const scope = this._scope
  if (!scope) return

  scope.$applyAsync(function () {
    delete scope.feature
    delete scope.properties
  })
}
