angular
  .module('atelier.config')
  .provider('appConfig', appconfigProvider)

appconfigProvider['$inject'] = ['objectPath']
function appconfigProvider (objectPath) {
  const config = {}

  // Bindable factory
  // ---------------------------------------------------------------------------
  this.$get = configFactory

  // Bindable provider members
  // ---------------------------------------------------------------------------
  this.get    = get
  this.set    = set
  this.extend = extend

  // Public provider functions
  // ---------------------------------------------------------------------------
  function get (path) {
    return objectPath.get(config, path)
  }

  function set (path, value) {
    objectPath.set(config, path, value)
    return this
  }

  function extend () {
    if (arguments.length === 1) {
      const value = arguments[0]

      angular.extend(config, value)
    } else {
      const path   = arguments[0]
      const value  = arguments[1]
      const object = get(path) || {}

      angular.extend(object, value)
      set(path, object)
    }

    return this
  }

  // Factory definition
  // ---------------------------------------------------------------------------
  // configFactory['$inject] = []
  function configFactory () {
    return {
      config: config,
      find:   get
    }
  }
}
