const moment = require('moment')

angular
  .module('atelier')
  .config(dateLocaleConfig)

dateLocaleConfig['$inject'] = ['$mdDateLocaleProvider']
function dateLocaleConfig ($mdDateLocaleProvider) {
  $mdDateLocaleProvider.months         = 'janvier février mars avril mai juin juillet août septembre octobre novembre décembre'.split(' ')
  $mdDateLocaleProvider.shortMonths    = 'Jan. Fév. Mars Avr. Mai Juin Juil. Août Sept. Oct. Nov. Déc.'.split(' ')
  $mdDateLocaleProvider.days           = 'dimanche lundi mardi mercredi jeudi vendredi samedi'.split(' ')
  $mdDateLocaleProvider.shortDays      = 'D L M M J V S'.split(' ')
  $mdDateLocaleProvider.firstDayOfWeek = 1

  $mdDateLocaleProvider.msgCalendar     = 'Calendrier'
  $mdDateLocaleProvider.msgOpenCalendar = 'Ouvrir le calendrier'

  $mdDateLocaleProvider.parseDate = function (value) {
    const date = moment(value, 'DD/MM/YYYY', true)
    return date.isValid() ? date.toDate() : new Date(NaN)
  }

  $mdDateLocaleProvider.formatDate = function (value) {
    const date = moment(value)
    return date.isValid() ? date.format('DD/MM/YYYY') : ''
  }
}
