angular
  .module('atelier.uploaders')
  .component('photosUploader', {
    controller: Controller,
    template:   require('./photos_uploader.template.pug')(),
    bindings:   {
      photos:   '=',
      onAdd:    '&',
      onRemove: '&',
      onUpdate: '&'
    }
  })

Controller['$inject'] = ['$element', '$timeout', '$q', '$mdDialog', 'FileUploader', 'ActiveStorage']
function Controller ($element, $timeout, $q, $mdDialog, FileUploader, ActiveStorage) {
  const ctrl = this

  ctrl.add    = add
  ctrl.remove = remove
  ctrl.update = update

  ctrl.uploader = new FileUploader({
    autoUpload:        false,
    onAfterAddingFile: afterAddingFile,
    onSuccessItem:     afterUploadingFile,
    onErrorItem:       afterUploadError
  })

  // Public functions
  // -----------------------------------------------------------------------
  function add () {
    if (ctrl.clickTriggered) return

    $timeout(function () {
      ctrl.clickTriggered = true
      $element.find('input[type="file"]').click()
      ctrl.clickTriggered = false
    })
  }

  function remove (photo, event) {
    $mdDialog.show({
      controllerAs:        '$ctrl',
      template:            require('./remove.template.pug')(),
      openFrom:            event,
      bindToController:    true,
      clickOutsideToClose: true,
      escapeToClose:       true,
      controller:          function () {
        this.cancel  = $mdDialog.cancel
        this.confirm = function () {
          removePhotoFromCollection(photo)
          ctrl.onRemove({ $photo: photo })
          $mdDialog.hide()
        }
      }
    }).catch(function (error) {
      if (error !== 'canceled') return $q.reject(error)
    })
  }

  function update (photo, event) {
    $mdDialog.show({
      controllerAs:     '$ctrl',
      template:         require('./update.template.pug')(),
      openFrom:         event,
      bindToController: true,
      controller:       function () {
        this.message = angular.copy(photo.message)
        this.cancel  = $mdDialog.cancel
        this.submit  = function () {
          photo.message = this.message
          ctrl.onUpdate({ $photo: photo })
          $mdDialog.hide()
        }
      }
    }).catch(function (error) {
      if (error !== 'canceled') return $q.reject(error)
    })
  }

  // Private functions
  // -----------------------------------------------------------------------
  function afterAddingFile (item) {
    const photo = { uploading: true }

    ctrl.photos.push(photo)
    item.photo   = photo
    ctrl.current = photo

    ActiveStorage.sign(item._file).then(response => {
      item.photo.attachment   = response.data.signed_id
      item.url                = response.data.direct_upload.url
      item.headers            = response.data.direct_upload.headers
      item.formData           = response.data
      item.method             = 'PUT'
      item.useCORS            = true
      item.disableMultipart   = true
      item.upload()
    })
  }

  function afterUploadingFile (item) {
    ctrl.onAdd({ $photo: item.photo })
  }

  function afterUploadError (item) {
    removePhotoFromCollection(item.photo)
  }

  function removePhotoFromCollection (photo) {
    const index = ctrl.photos.indexOf(photo)

    if (photo.id) {
      photo._destroy = true
    } else if (index !== -1) {
      ctrl.photos.splice(index, 1)
    }

    if (photo === ctrl.current) {
      const nextPhoto = ctrl.photos.find(function (other, otherIndex) {
        return !other._destroy && otherIndex > index
      }) || ctrl.photos.find(function (other, otherIndex) {
        return !other._destroy && otherIndex < index
      })

      ctrl.current = nextPhoto
    }
  }
}
