angular
  .module('atelier.sidebar')
  .component('sidebarToggleButton', {
    controller: angular.noop,
    template:   require('./sidebar_toggle_button.template.pug')(),
    require:    {
      sidebarCtrl:          '^^sidebar',
      minimizedSidebarCtrl: '?^^minimizedSidebar'
    }
  })
