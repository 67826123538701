angular
  .module('atelier.dialogComponent')
  .provider('dialogComponent', dialogComponentProvider)

// dialogComponentProvider['$inject] = []
function dialogComponentProvider () {
  const defaults = {
    bindToController:    true,
    clickOutsideToClose: true,
    escapeToClose:       true,
    focusOnOpen:         false,
    controller:          angular.noop,
    controllerAs:        '$dialog'
  }

  // Bindable factory
  // ---------------------------------------------------------------------------
  this.$get = dialogComponentFactory

  // Bindable provider members
  // ---------------------------------------------------------------------------
  this.setDefaults = setDefaults

  // Public provider functions
  // ---------------------------------------------------------------------------
  function setDefaults (values) {
    angular.extend(defaults, values)
    return this
  }

  // Factory definition
  // ---------------------------------------------------------------------------
  dialogComponentFactory['$inject'] = ['$mdDialog']
  function dialogComponentFactory ($mdDialog) {
    return {
      show:   show,
      showV4: showV4
    }

    // Public factory functions
    // -------------------------------------------------------------------------
    function show (options) {
      options        = angular.extend({}, defaults, options)
      options.locals = angular.extend({}, defaults.locals, options.locals)

      const ctrl      = options.controllerAs
      const component = options.template

      if ('ariaLabel' in options) options.locals.ariaLabel = options.ariaLabel

      delete options.template
      delete options.ariaLabel

      options.template = '<md-dialog aria-label="{{ ' + ctrl + '.ariaLabel }}">' + component + '</md-dialog>'

      return $mdDialog.show(options)
    }

    function showV4 (url) {
      const iframe = document.createElement("iframe")

      iframe.classList.add("modal-v4-integration")
      iframe.id  = "modal-v4-integration"
      iframe.src = url

      document.body.appendChild(iframe)
      $mdDialog.cancel()
    }
  }
}

window.closeModalV4Integration = () => {
  $("#modal-v4-integration").remove();
}