angular
  .module('atelier.sidebar')
  .component('sidebar', {
    controller: SidebarController,
    bindings:   {
      isOpened: '<?sidebarIsOpened',
      onToggle: '&?sidebarOnToggle'
    }
  })

SidebarController['$inject'] = ['$scope', '$element', '$animate', '$mdUtil']
function SidebarController ($scope, $element, $animate, $mdUtil) {
  const ctrl = this

  ctrl.$onInit = onInit
  ctrl.open    = open
  ctrl.close   = close

  // Hook
  // -------------------------------------------------------------------------
  function onInit () {
    if (!ctrl.isOpened) $element.addClass('sidebar-closed')
    $scope.$watch('$ctrl.isOpened', updateIsOpened)
  }

  // Public functions
  // -------------------------------------------------------------------------
  function open () {
    updateIsOpened(true)
  }

  function close () {
    updateIsOpened(false)
  }

  // Private functions
  // -------------------------------------------------------------------------
  function updateIsOpened (isOpened) {
    $animate[isOpened ? 'removeClass' : 'addClass']($element, 'sidebar-closed').then(function () {
      if (angular.isFunction(ctrl.onToggle)) ctrl.onToggle({ $isOpened: isOpened })

      if (isOpened) {
        const focusElement = $mdUtil.findFocusTarget($element)
        if (focusElement) focusElement.focus()
      }
    })
  }
}
