import authRedirection from './services/auth_redirection.js'

angular
  .module('atelier')
  .config(httpConfig)

httpConfig['$inject'] = ['$httpProvider']
function httpConfig ($httpProvider) {
  $httpProvider.defaults.headers.common['Accept']           = 'application/json'
  $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  $httpProvider.defaults.headers.common['X-CSRF-Token']     = angular.element(document.querySelector('meta[name=csrf-token]')).attr('content')
  $httpProvider.defaults.headers.delete  = { 'Content-Type': 'application/json' }
  $httpProvider.defaults.paramSerializer = '$httpParamSerializerJQLike'

  // loadingBarInterceptor must be inserted after authRedirection
  // to be evaluated before
  $httpProvider.interceptors.push(authRedirection)
  $httpProvider.interceptors.push('loadingBarInterceptor')
}
