angular
  .module('atelier')
  .filter('trustHtml', trustHtml)

trustHtml['$inject'] = ['$sce']
function trustHtml ($sce) {
  return function (value) {
    return $sce.trustAsHtml(value)
  }
}
