/**
 * Binds to request:screenShot event to trigger the actual screenShot
 */
angular
  .module('atelier.GIS')
  .factory('LeafletModules.Photo', Photo)

Photo['$inject'] = ['$q']
function Photo ($q) {
  return function () {
    const mod  = this
    let leaflet, service

    // Hooks
    mod.$onInit     = onInit
    mod.$onDestroy  = onDestroy

    // Hooks
    // -----------------------------------------------------------------------
    function onInit () {
      leaflet = mod.$leaflet
      service = mod.$service

      service.on('request:screenShot', takeScreenShot)
    }

    function onDestroy () {
      service.off('request:screenShot', takeScreenShot)
    }

    // Private functions
    // -----------------------------------------------------------------------
    function takeScreenShot () {
      service.trigger('screenShooted', {
        location: leaflet.map.getCenter(),
        zoom:     leaflet.map.getZoom(),
        size:     leaflet.map.getSize()
      })
    }
  }
}
