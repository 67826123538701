/**
 * Handles measuring a distance in a map and the content of the associated box.
 * ctrl.leafletCtrl.oneBox.open('<gis-measure latlng=latlng layer=layer>', options)
 */
angular
  .module('atelier.GIS')
  .component('gisMeasure', {
    controller: MeasureController,
    template:   require('./measure.template.pug')(),
    bindings:   {
      latlng: '<',
      layer:  '<'
    },
    require: {
      leafletCtrl: '^^leaflet'
    }
  })

MeasureController['$inject'] = ['$timeout']
function MeasureController ($timeout) {
  const ctrl = this
  let map

  // Bindable members
  // -------------------------------------------------------------------------
  ctrl.$onInit    = onInit
  ctrl.$onDestroy = onDestroy
  ctrl.restart    = restart

  // Hooks
  // -------------------------------------------------------------------------
  function onInit () {
    map  = ctrl.leafletCtrl.map

    // Define tooltip text inside onAdd, because another tool
    // may redefine different behaviors and so, different tooltips
    L.drawLocal.draw.handlers.polygon.tooltip = {
      start: 'Cliquez pour commencer à tracer une ligne.',
      cont:  'Cliquez pour continuer à tracer une ligne.',
      end:   'Cliquez sur le premier point ou appuyez sur Echap pour terminer.'
    }

    ctrl.draw = new L.Draw.Polygon(map)

    if (ctrl.layer) {
      ctrl.polygon = L.polygon(ctrl.layer.getLatLngs())
      ctrl.polygon.addTo(map)
    } else if (ctrl.latlng) {
      ctrl.draw.enable()
      ctrl.draw.addVertex(ctrl.latlng)
    }

    L.JSTS.inject().then(function () {
      updateMeasures()
    })

    map.on('draw:drawvertex', updateMeasures, this)
    map.on('draw:created', onDrawCompleted, this)
    map.on('draw:canceled', onDrawCompleted, this)
  }

  function onDestroy () {
    if (ctrl.polygon) ctrl.polygon.remove()
    ctrl.draw.disable()

    map.off('draw:drawvertex', updateMeasures, this)
    map.off('draw:created', onDrawCompleted, this)
    map.off('draw:canceled', onDrawCompleted, this)
  }

  // Public function
  // -------------------------------------------------------------------------
  function restart () {
    ctrl.polygon.remove()
    ctrl.draw.enable()
    updateMeasures()
  }

  // Private function
  // -------------------------------------------------------------------------
  function onDrawCompleted (event) {
    event.layer.addTo(map)

    $timeout(function () {
      ctrl.polygon = event.layer
    })
  }

  function updateMeasures () {
    let ring, holes

    if (ctrl.polygon) {
      const rings = ctrl.polygon.getLatLngs()
      ring  = rings[0]
      holes = rings.slice(1)
    } else {
      ring  = ctrl.draw._poly.getLatLngs()
      holes = []
    }

    const distance = measureDistance(ring)
    const area     = measureArea(ring, holes)

    $timeout(function () {
      ctrl.points   = ring.length
      ctrl.distance = L.GeometryUtil.readableDistance(distance, true)
      ctrl.area     = L.GeometryUtil.readableArea(area, true)
    })
  }

  function measureDistance (points) {
    if (points.length <= 1) {
      return 0
    } else {
      let distance      = 0
      let previousPoint = points[0]

      for (let i = 1; i < points.length; i++) {
        distance += map.distance(previousPoint, points[i])
        previousPoint = points[i]
      }

      return distance
    }
  }

  function measureArea (outerRing, holes) {
    let area = L.GeometryUtil.geodesicArea(outerRing)

    holes.forEach(function (hole) {
      area -= L.GeometryUtil.geodesicArea(hole)
    })

    return area
  }
}
