/**
 * Opens a street_view_panorama component with the possibility to take a photo
 */
require('./street_view_panorama.component.js')

angular
  .module('atelier.GIS')
  .component('streetView', {
    controller: Controller,
    template:   require('./street_view.template.pug')(),
    bindings:   {
      service: '=gis'
    }
  })

Controller['$inject'] = ['$timeout', '$element', 'thirdParty']
function Controller ($timeout, $element, thirdParty) {
  const ctrl = this

  // Bindable members
  // -------------------------------------------------------------------------
  ctrl.$onInit   = onInit
  ctrl.takePhoto = takePhoto

  // Component hooks
  // -------------------------------------------------------------------------
  function onInit () {
    ctrl.service
      .checkProvider('google')
      .then(function () {
        return thirdParty.inject('googleMaps').then(function (googleMaps) {
          ctrl.googleMaps = googleMaps
        })
      })
      .catch(function () {
        ctrl.placeholder = "Street View n'est pas disponible.<br>Veuillez contactez votre administrateur pour plus d'informations."
      })
      .then(function () {
        ctrl.ready = true
      })
  }

  // Public function
  // -------------------------------------------------------------------------
  function takePhoto () {
    ctrl.photoSnap = true

    $timeout(function () {
      ctrl.photoSnap = false
    }, 100)

    const panorama = ctrl.service.get('streetViewPanorama')

    ctrl.service.trigger('streetViewPhotoTook', {
      location: panorama.getPosition().toJSON(),
      heading:  panorama.getPov().heading,
      pitch:    panorama.getPov().pitch,
      zoom:     panorama.getZoom() || 1.0,
      size:     {
        x: $element[0].offsetWidth,
        y: $element[0].offsetHeight
      }
    })
  }
}
