angular
  .module('atelier.expandable')
  .component('expandButton', {
    template: require('./expand_button.template.pug')(),
    require:  {
      expandableCtrl: '^^expandable'
    },
    bindings: {
      target:   '=expand',
      label:    '@expandLabel',
      disabled: '<expandDisabled'
    }
  })
