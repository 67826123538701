/**
 * Handles the behavior and display of the leaflet sidebar
 */
angular
  .module('atelier.GIS')
  .component('leafletSidebar', {
    controller: SidebarController,
    template:   require('./sidebar.template.pug')(),
    require:    {
      leafletCtrl: '^^leaflet'
    }
  })

SidebarController['$inject'] = ['$timeout']
function SidebarController ($timeout) {
  const ctrl = this
  let basemaps, overlays

  ctrl.$onInit            = onInit
  ctrl.$onDestroy         = onDestroy
  ctrl.updateBaselayer    = updateBaselayer
  ctrl.updateOverlay      = updateOverlay
  ctrl.toggleGroupOverlay = toggleGroupOverlay

  // Hooks
  // -------------------------------------------------------------------------
  function onInit () {
    basemaps = ctrl.leafletCtrl.basemaps
    overlays = ctrl.leafletCtrl.overlays

    basemaps.ready ? onBasemapsReady() : basemaps.on('basemapsReady', onBasemapsReady)
    overlays.ready ? onOverlaysReady() : overlays.on('overlaysReady', onOverlaysReady)
  }

  function onDestroy () {
    basemaps.off('basemapsReady', onBasemapsReady)
    overlays.off('ready', onOverlaysReady)
  }

  function onBasemapsReady () {
    basemaps.off('basemapsReady', onBasemapsReady)

    ctrl.basemaps         = basemaps.groups
    ctrl.currentBaselayer = basemaps.current
  }

  function onOverlaysReady () {
    overlays.off('overlaysReady', onOverlaysReady)

    ctrl.overlays = overlays.groups

    angular.forEach(ctrl.overlays, function (group) {
      group.opened = group.uid && ['territoires', 'edigeo'].includes(group.uid)
    })

    updateActiveLayers()
  }

  // Public functions
  // -------------------------------------------------------------------------
  function updateBaselayer () {
    basemaps.setCurrent(ctrl.currentBaselayer)
  }

  function updateOverlay (overlay) {
    if (overlay.checked) {
      overlays.show(overlay.layer)
    } else {
      overlays.hide(overlay.layer)
    }

    $timeout(updateActiveLayers)
  }

  function toggleGroupOverlay (group) {
    const checkIt = !group.checked

    $timeout(function () {
      angular.forEach(group.overlays, function (overlay) {
        overlay.checked = checkIt
        updateOverlay(overlay)
      })

      group.checked   = checkIt
      group.partially = false
    })
  }

  // Private functions
  // -------------------------------------------------------------------------
  function updateActiveLayers () {
    const map = ctrl.leafletCtrl.map

    angular.forEach(ctrl.overlays, function (group) {
      const overlays = group.overlays
      let checked  = 0

      angular.forEach(overlays, function (overlay) {
        if (map.hasLayer(overlay.layer)) {
          overlay.checked = true
          checked += 1
        }
      })

      group.checked   = checked > 0 && checked === overlays.length
      group.partially = checked > 0 && checked < overlays.length
    })
  }
}
