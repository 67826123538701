/**
 * Injects the different basemaps and sets the default one
 */
angular
  .module('atelier.GIS')
  .factory('LeafletModules.Basemaps', Basemaps)

Basemaps['$inject'] = ['$q', '$log', 'GIS.Config', 'eventHandlers', 'thirdParty']
function Basemaps ($q, $log, Config, eventHandlers, thirdParty) {
  return function () {
    const mod = this
    let leaflet, service, groups, layers
    let esri, proj

    eventHandlers.extendObject(mod)

    // Variable vocabulary used in this module:
    //  - basemap defines an object as returned by the service with all its config, uid, etc..
    //  - layer   defines the actual layer added/removed to leaflet map

    // Hooks
    mod.$onInit    = onInit

    // Functions
    mod.setCurrent  = setCurrent
    mod.forceUpdate = forceUpdate

    // Properties
    mod.groups = groups = []
    mod.layers = layers = {}

    // Hooks
    // -----------------------------------------------------------------------
    function onInit () {
      leaflet = mod.$leaflet
      service = mod.$service

      initLayers().then(activateDefault).then(function () {
        mod.ready = true
        mod.trigger('basemapsReady')
      })
    }

    // Public functions
    // -----------------------------------------------------------------------
    function setCurrent (layer) {
      if (angular.equals(layer, mod.current)) return

      const map = leaflet.map
      if (mod.current && map.hasLayer(mod.current)) map.removeLayer(mod.current)

      mod.current = layer
      service.cache.put('basemap', layer.uid)

      map.addLayer(layer)
      map.fire('baselayerchange', {
        layer: layer
      })
    }

    function forceUpdate () {
      [
        'google.roadmap',
        'google.satellite',
        'google.plain'
      ].forEach(function (uid) {
        if (leaflet.map.hasLayer(layers[uid])) {
          layers[uid]._resize()
        }
      })
    }

    // Private functions: building basemap layers
    // -----------------------------------------------------------------------
    function initLayers () {
      return $q.all({
        providers: service.getProviders(),
        extra:     service.getExtraBasemaps()
      }).then(function (results) {
        const promises = [$q.resolve()]

        angular.forEach(results.providers, function (provider) {
          if (provider === 'esri') promises.push(injectEsri())
          if (provider === 'google') promises.push(injectGoogleMap())
        })

        angular.forEach(results.extra, function (config) {
          if (config.type === 'arcgis_tiles') promises.push(injectEsri())
          if (config.options && config.options.projection) promises.push(injectProj4())
        })

        return $q.all(promises).then(function () {
          angular.forEach(results.providers, function (provider) {
            if (provider === 'esri') addEsri()
            if (provider === 'google') addGoogle()
            if (provider === 'osm') addOsm()
          })

          addIGN()
          addGeoportail()

          angular.forEach(results.extra, function (config, name) {
            config.name = name

            if (config.type === 'wms') addWMS(config)
            if (config.type === 'wmts') addWMTS(config)
            if (config.type === 'arcgis_tiles') addArcgisTiles(config)
          })
        })
      })
    }

    // Private functions: inject third parties
    // -----------------------------------------------------------------------
    function injectGoogleMap () {
      return thirdParty.inject('googleMaps')
    }

    function injectEsri () {
      return thirdParty.inject('esri').then(function (result) {
        esri = result
      })
    }

    function injectProj4 () {
      return thirdParty.inject('proj4leaflet').then(function (result) {
        proj = result
      })
    }

    // Private functions: add basemaps
    // -----------------------------------------------------------------------
    function addEsri () {
      registerBasemap('ESRI', 'Topographie', 'esri.roadmap', esri.basemapLayer('Topographic'))
      registerBasemap('ESRI', 'Rues', 'esri.street', esri.basemapLayer('Streets'))
      registerBasemap('ESRI', 'Imagerie', 'esri.satellite', L.layerGroup([esri.basemapLayer('Imagery'), esri.basemapLayer('ImageryLabels')]))
    }

    function addGoogle () {
      registerBasemap('Google', 'Cartographie', 'google.roadmap', new L.GridLayer.GoogleMutant({ type: 'roadmap', styles: Config.googleRoadmapStyles }))
      registerBasemap('Google', 'Satellite', 'google.satellite', new L.GridLayer.GoogleMutant({ type: 'hybrid', styles: Config.googleRoadmapStyles }))
      registerBasemap('Google', 'Fond Uni', 'google.plain', new L.GridLayer.GoogleMutant({ type: 'roadmap', styles: Config.googlePlainStyles }))
    }

    function addOsm () {
      registerBasemap('OpenStreetMap', 'Cartographie', 'osm', L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png'))
    }

    function addIGN () {
      registerIGNBasemap('Plan', 'ign.plan', 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2', 'image/png')
      registerIGNBasemap('Photographies aeriennes', 'ign.orthophotos', 'ORTHOIMAGERY.ORTHOPHOTOS', 'image/jpeg')
      registerIGNBasemap('BD ORTHO® 20 cm', 'ign.orthophotos_hr', 'HR.ORTHOIMAGERY.ORTHOPHOTOS', 'image/jpeg')
      registerIGNBasemap('Parcellaire Express (PCI)', 'ign.basemap_parcellaire', 'CADASTRALPARCELS.PARCELLAIRE_EXPRESS', 'image/png')
      registerIGNBasemap('BD PARCELLAIRE® MAJ2018', 'ign.basemap_parcellaire_maj2018', 'CADASTRALPARCELS.PARCELS', 'image/png')
    }

    function addGeoportail () {
      registerGeoportailBasemap("Plan local d'urbanisme", 'geoportail.zone_secteur', ['municipality', 'zone_secteur'], 'image/png')
    }

    function registerGeoportailBasemap (name, uid, layerNames, format) {
      const layer = L.tileLayer.wms('https://data.geopf.fr/wms-v/ows', {
        version: '1.3.0',
        layers: layerNames,
        format: format,
        transparent: true,
      })

      registerBasemap('Géoportail Urbanisme', name, uid, layer)
    }

    function registerIGNBasemap (name, uid, layerName, format) {
      const layer = L.tileLayer('https://data.geopf.fr/wmts?service=wmts&request=GetTile&version=1.0.0&layer=' + layerName + '&tilematrixset=PM&tilematrix={z}&tilecol={x}&tilerow={y}&format=' + format + '&style=normal', {
        minZoom:     0,
        maxZoom:     19,
        tileSize:    256,
        attribution: 'IGN-F/Géoportail'
      })

      registerBasemap('IGN', name, uid, layer)
    }

    function addWMS (config) {
      const layer = L.tileLayer.wms(config.url, {
        layers:  config.options.layers,
        version: config.options.version,
        maxZoom: 21
      })

      registerBasemap('Fonds personnalisés', config.name, config.uid, layer, config.options)
    }

    function addWMTS (config) {
      const layer = L.tileLayer(config.url, {
        minZoom:  0,
        maxZoom:  21,
        tileSize: 256,
        version:  config.options.version
      })

      registerBasemap('Fonds personnalisés', config.name, config.uid, layer, config.options)
    }

    function addArcgisTiles (config) {
      const layer = esri.tiledMapLayer({
        url:        config.url,
        minZoom:    config.options.minZoom,
        maxZoom:    config.options.maxZoom,
        zoomOffset: config.options.zoomOffset
      })

      registerBasemap('Fonds personnalisés', config.name, config.uid, layer, config.options)
    }

    function registerBasemap (groupName, name, uid, layer, options) {
      layer.uid   = uid
      layers[uid] = layer

      // Build groups for sidebar
      if (!groups[groupName]) {
        groups[groupName] = {
          name:     groupName,
          basemaps: []
        }

        groups.push(groups[groupName])
      }

      groups[groupName].basemaps.push({
        uid:     uid,
        name:    name,
        layer:   layer,
        options: options
      })

      handleCRSOnLayer(layer, options || {})

      // Propagate Google Mutant event to the map
      layer.on('spawned', function (e) {
        leaflet.map.fire('layerSpawned', e)
      })
    }

    // Private functions: activate default layer
    // -----------------------------------------------------------------------
    function activateDefault () {
      return getDefaultLayer().then(setCurrent, angular.noop)
    }

    function getDefaultLayer () {
      return service.getDefaultProvider().then(function (provider) {
        let uid     = service.cache.get('basemap')
        let basemap = uid && layers[uid]

        if (!basemap && provider) {
          uid     = provider + '.' + service.options.defaultBasemap
          basemap = layers[uid]
        }

        if (!basemap) {
          Config.basemaps.forEach(function (uid) {
            basemap = layers[uid]

            if (basemap && (!provider || uid.includes(provider))) return undefined
          })
        }

        if (!basemap) return $q.reject()
        return basemap
      })
    }

    // Private functions: handle other CRS
    // -----------------------------------------------------------------------
    function handleCRSOnLayer (layer, options) {
      let crs

      if (angular.isDefined(options)) {
        if (options.projection) {
          crs = new proj.CRS(options.crs, options.projection, {
            scale:       options.scales,
            resolutions: options.resolutions,
            origin:      options.origin
          })
        } else if (options.crs) {
          crs = L.CRS[options.crs.replace(':', '')]
        }
      }

      if (angular.isUndefined(crs)) {
        crs = L.CRS.EPSG3857
      }

      // HACKME:
      // https://github.com/Leaflet/Leaflet/issues/2553
      // https://gitlab.com/IvanSanchez/Leaflet.GridLayer.GoogleMutant/issues/22
      layer.on('add', function () {
        const center = leaflet.map.getCenter()
        const zoom   = leaflet.map.getZoom()

        if (leaflet.map.options.crs === crs) return

        leaflet.map.options.crs = crs
        leaflet.map._resetView(center, zoom, true)

        // Redraw google mutant
        if (layer.uid.includes('google')) layer._resize()
      })
    }
  }
}
