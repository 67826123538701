angular
  .module('atelier.config')
  .filter('configs', configsFilter)

configsFilter['$inject'] = ['appConfig']
function configsFilter (appConfig) {
  return function (keys, scope, noError) {
    let result = {}

    keys.forEach(function(key) {
      if (scope) key = scope + '.' + key

      const value = appConfig.find(key)
      if (angular.isUndefined(value) && !noError) {
        console.error('undefined config value: ' + key)
      } else {
        angular.merge(result, value)
      }
    })

    return result
  }
}
