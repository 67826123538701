angular
  .module('atelier.GIS')
  .factory('LeafletModules', Modules)

Modules['$inject'] = [
  'LeafletModules.Basemaps',
  'LeafletModules.Bounds',
  'LeafletModules.CurrentMarker',
  'LeafletModules.Markers',
  'LeafletModules.Overlays',
  'LeafletModules.Pegman',
  'LeafletModules.Photo',
  'LeafletModules.Selection'
]
function Modules (
  Basemaps,
  Bounds,
  CurrentMarker,
  Markers,
  Overlays,
  Pegman,
  Photo,
  Selection
) {
  return {
    Basemaps:      Basemaps,
    Bounds:        Bounds,
    CurrentMarker: CurrentMarker,
    Markers:       Markers,
    Overlays:      Overlays,
    Pegman:        Pegman,
    Photo:         Photo,
    Selection:     Selection
  }
}
