// https://github.com/Leaflet/Leaflet/pull/7100
// This is the overwritten function, which accept no precision
function latLngToCoords (latlng, precision) {
  if (precision === false) {
    return latlng.alt !== undefined ? [latlng.lng, latlng.lat, latlng.alt] : [latlng.lng, latlng.lat]
  } else {
    return latlng.alt !== undefined
      ? [L.Util.formatNum(latlng.lng, precision), L.Util.formatNum(latlng.lat, precision), L.Util.formatNum(latlng.alt, precision)]
      : [L.Util.formatNum(latlng.lng, precision), L.Util.formatNum(latlng.lat, precision)]
  }
}

// All the methods below are copied from Leafleat to use the patch above
function latLngsToCoords (latlngs, levelsDeep, closed, precision) {
  const coords = []

  for (let i = 0, len = latlngs.length; i < len; i++) {
    coords.push(levelsDeep
      ? latLngsToCoords(latlngs[i], levelsDeep - 1, closed, precision)
      : latLngToCoords(latlngs[i], precision))
  }

  if (!levelsDeep && closed) {
    coords.push(coords[0])
  }

  return coords
}

function isFlat (latlngs) {
  return !isArray(latlngs[0]) || (typeof latlngs[0][0] !== 'object' && typeof latlngs[0][0] !== 'undefined')
}

const isArray = Array.isArray || function (obj) {
  return (Object.prototype.toString.call(obj) === '[object Array]')
}

function getFeature (layer, newGeometry) {
  return layer.feature
    ? extend({}, layer.feature, { geometry: newGeometry })
    : asFeature(newGeometry)
}

function extend (dest) {
  let i, j, len, src

  for (j = 1, len = arguments.length; j < len; j++) {
    src = arguments[j]
    for (i in src) {
      dest[i] = src[i]
    }
  }
  return dest
}

function asFeature (geojson) {
  if (geojson.type === 'Feature' || geojson.type === 'FeatureCollection') {
    return geojson
  }

  return {
    type:       'Feature',
    properties: {},
    geometry:   geojson
  }
}

L.GeoJSON.latLngToCoords = latLngToCoords
L.GeoJSON.latLngsToCoords = latLngsToCoords

const PointToGeoJSON = {
  toGeoJSON: function (precision) {
    return getFeature(this, {
      type:        'Point',
      coordinates: latLngToCoords(this.getLatLng(), precision)
    })
  }
}

L.Marker.include(PointToGeoJSON)
L.Circle.include(PointToGeoJSON)
L.CircleMarker.include(PointToGeoJSON)

L.Polyline.include({
  toGeoJSON: function (precision) {
    const multi = !isFlat(this._latlngs)

    const coords = latLngsToCoords(this._latlngs, multi ? 1 : 0, false, precision)

    return getFeature(this, {
      type:        (multi ? 'Multi' : '') + 'LineString',
      coordinates: coords
    })
  }
})

L.Polygon.include({
  toGeoJSON: function (precision) {
    const holes = !isFlat(this._latlngs)
    const multi = holes && !isFlat(this._latlngs[0])

    let coords = latLngsToCoords(this._latlngs, multi ? 2 : holes ? 1 : 0, true, precision)

    if (!holes) {
      coords = [coords]
    }

    return getFeature(this, {
      type:        (multi ? 'Multi' : '') + 'Polygon',
      coordinates: coords
    })
  }
})
