import localeDecorator from './services/locale_decorator.js'

angular
  .module('atelier')
  .config(localeConfig)

localeConfig['$inject'] = ['$provide']
function localeConfig ($provide) {
  $provide.decorator('$locale', localeDecorator)
}
