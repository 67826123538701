/**
 * Handles the fetching and displaying of the different layers
 */
angular
  .module('atelier.GIS')
  .factory('LeafletModules.Overlays', Overlays)

Overlays['$inject'] = ['$q', '$log', 'eventHandlers', 'thirdParty']
function Overlays ($q, $log, eventHandlers, thirdParty) {
  return function () {
    const mod  = this
    let leaflet, service, groups, layers, geoJsonLayer
    let esri

    eventHandlers.extendObject(mod)

    // Variable vocabulary used in this module:
    //  - overlay defines an object as returned by the service with all its config, uid, etc..
    //  - layer   defines the actual layer added/removed to leaflet map

    // Hooks
    mod.$onInit    = onInit
    mod.$onDestroy = onDestroy

    // Functions
    mod.hide       = hideLayer
    mod.show       = showLayer
    mod.toggle     = toggleLayer
    mod.eachLayers = eachLayers

    // Properties
    mod.groups  = groups = []
    mod.layers  = layers = {}

    // Hooks
    // -----------------------------------------------------------------------
    function onInit () {
      leaflet = mod.$leaflet
      service = mod.$service

      initLayers().then(activateDefaults).then(function () {
        mod.ready = true
        mod.trigger('overlaysReady')
      })

      service.on('request:showOverlays', showOverlays)
      service.on('request:hideOverlays', hideOverlays)

      service.on('request:showGeoJsonLayer', showGeoJsonLayer)
      service.on('request:hideGeoJsonLayer', hideGeoJsonLayer)
    }

    function onDestroy () {
      hideGeoJsonLayer()

      service.off('request:showOverlays', showOverlays)
      service.off('request:hideOverlays', hideOverlays)

      service.off('request:showGeoJsonLayer', showGeoJsonLayer)
      service.off('request:hideGeoJsonLayer', hideGeoJsonLayer)
    }

    // Public functions
    // -----------------------------------------------------------------------
    function hideLayer (layer) {
      if (!leaflet.map.hasLayer(layer)) return

      leaflet.map.removeLayer(layer)
      leaflet.map.fire('overlayremove ', {
        layer: layer
      })

      const cache = service.cache.get('overlays') || []
      const index = cache.indexOf(layer.uid)
      if (index > -1) cache.splice(index, 1)
      service.cache.put('overlays', cache)
    }

    function showLayer (layer) {
      if (leaflet.map.hasLayer(layer)) return

      leaflet.map.addLayer(layer)
      leaflet.map.fire('overlayadd', {
        layer: layer
      })

      const cache = service.cache.get('overlays') || []
      if (!cache.includes(layer.uid)) cache.push(layer.uid)
      service.cache.put('overlays', cache)
    }

    function toggleLayer (layer) {
      if (leaflet.map.hasLayer(layer)) {
        hideLayer(layer)
      } else {
        showLayer(layer)
      }
    }

    // Private functions: build layers
    // -----------------------------------------------------------------------
    function initLayers () {
      return service.getOverlays().then(function (overlays) {
        const promises = [$q.resolve()]

        angular.forEach(overlays, function (group) {
          angular.forEach(group.layers, function (overlay) {
            if (overlay.type === 'arcgis') promises.push(injectEsri())
          })
        })

        return $q.all(promises).then(function () {
          angular.forEach(overlays, function (group) {
            angular.forEach(group.layers, function (overlay) {
              addOverlay(overlay, group)
            })
          })
        })
      })
    }

    function injectEsri () {
      return thirdParty.inject('esri').then(function (result) {
        esri = result
      })
    }

    function addOverlay (overlay, group) {
      if (!isOverlayAllowed(overlay)) return

      const layer = buildLayer(overlay, group)
      if (!layer) return

      const groupUid = group.uid
      const layerUid = overlay.uid
      if (!groupUid) $log.warn('No UID specified for', group)
      if (!layerUid) $log.warn('No UID specified for', overlay)

      layer.uid        = layerUid
      layers[layerUid] = layer

      // Build groups for sidebar
      if (!groups[groupUid]) {
        groups[groupUid] = {
          uid:      groupUid,
          name:     group.name,
          overlays: []
        }

        groups.push(groups[groupUid])
      }

      groups[groupUid].overlays.push({
        uid:   layerUid,
        name:  overlay.name,
        layer: layer,
        style: layer.options.style
      })

      layer.on('mouseover', onMouseOver)
      layer.on('mouseout', onMouseOut)

      layer.on('add', onAdd)
      layer.on('remove', onRemove)

      L.DomEvent.allowEventPropagationToMap(layer, 'contextmenu')
      L.DomEvent.allowEventPropagationToMap(layer, 'click')

      return layer
    }

    function buildLayer (overlay, group) {
      const options = buildLayerOptions(overlay, group)
      let layer

      if (overlay.type === 'ign') {
        layer = L.tileLayer(
          'https://data.geopf.fr/wmts?service=wmts&request=GetTile&version=1.0.0&layer=' + overlay.identifier + '&tilematrixset=PM&tilematrix={z}&tilecol={x}&tilerow={y}&format=image/png&style=' + (overlay.style || 'normal'),
          options
        )
      } else if (overlay.type === 'arcgis') {
        options.url = overlay.url
        try {
          layer = esri.featureLayer(options)
        } catch (e) {
          $log.error('Failed to initialize L.esri.FeatureLayer:', e)
          return
        }
      } else if (overlay.synced) {
        layer = L.geoJson.http.sync(overlay.url, options)
      } else if (overlay.url) {
        layer = L.geoJson.http(overlay.url, options)
      } else if (overlay.data) {
        layer = L.geoJson(overlay.data, options)
      }

      layer.checkTransparency = checkLayerTransparency.bind(mod, layer)

      return layer
    }

    function buildLayerOptions (overlay, _group) {
      const options = angular.copy(service.options.layers[overlay.type] || {})
      const color   = overlay.color

      if (options.colorize === 'stroke' || options.colorize === true) {
        options.style.color = color
      } else if (options.colorize === 'fill') {
        options.style.fillColor = color
      } else if (options.colorize === 'fillAndStrokeOnHover') {
        let fill   = color
        let stroke = color

        if (angular.isObject(color)) {
          fill   = color.fill
          stroke = color.stroke
        }

        options.style.fillColor = fill
        options.hover.color     = stroke
      }

      if (options.index) {
        angular.extend(options, { pane: buildPane(leaflet.map, options.index) })
      }

      return options
    }

    function buildPane (map, index) {
      const pane   = 'overlayPane' + index
      const paneEl = map.getPane(pane) || map.createPane(pane)

      paneEl.style.zIndex = 350 + index

      return pane
    }

    function isOverlayAllowed (overlay) {
      const allowedOverlays = service.options.overlays

      return angular.isUndefined(allowedOverlays) || allowedOverlays.includes(overlay.type)
    }

    function checkLayerTransparency (layer) {
      const maxZoom = layer.options.transparentZoom
      if (!maxZoom) return

      layer.options.fill = maxZoom > leaflet.map.getZoom()
      layer.setStyle({ fill: layer.options.fill })
    }

    // Private functions: events
    // -----------------------------------------------------------------------
    function onMouseOver (event) {
      const layer        = event.target
      const featureLayer = event.propagatedFrom
      const hover        = layer.options.hover

      if (hover) {
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) featureLayer.bringToFront()
        featureLayer.setStyle(hover)
      }

      leaflet.featureControl.show(featureLayer.feature)
    }

    function onMouseOut (event) {
      const layer        = event.target
      const featureLayer = event.propagatedFrom
      const style        = layer.options.style

      featureLayer.setStyle(style)
      leaflet.featureControl.hide()
    }

    function onAdd (event) {
      const checkTransparency = event.target.checkTransparency

      checkTransparency()
      leaflet.map.on('zoomend', checkTransparency)
    }

    function onRemove (event) {
      const checkTransparency = event.target.checkTransparency

      leaflet.map.off('zoomend', checkTransparency)
    }

    // Private functions: activate / hide layers
    // -----------------------------------------------------------------------
    function activateDefaults () {
      return $q.all([
        service.cache.get('overlays'),
        service.getDefaultOverlays(),
        service.get('requestedOverlays'),
        service.get('requestedGeoJsonLayer')
      ]).then(function (results) {
        showOverlays(results[0] || results[1] || [])
        if (results[2]) showOverlays(results[2])
        if (results[3]) showGeoJsonLayer(results[3])
      })
    }

    function showOverlays (uids) {
      eachLayers(uids, showLayer)
    }

    function hideOverlays (uids) {
      eachLayers(uids, hideLayer)
    }

    function eachLayers () {
      if (arguments.length === 1) {
        angular.forEach.apply(mod, arguments)
      } else {
        const uids  = arguments[0]
        const funct = arguments[1]

        angular.forEach(uids, function (uid) {
          if (layers[uid]) {
            funct(layers[uid])
          } else if (groups[uid]) {
            angular.forEach(groups[uid].overlays, function (overlay) {
              funct(overlay.layer)
            })
          }
        })
      }
    }

    function showGeoJsonLayer (json) {
      hideGeoJsonLayer()
      geoJsonLayer = L.geoJson(json).addTo(leaflet.map)
    }

    function hideGeoJsonLayer () {
      if (geoJsonLayer) geoJsonLayer.remove()
    }
  }
}
