angular
  .module('atelier.navbar')
  .component('navbarDropdown', {
    controller: DropdownController,
    bindings:   {
      href: '@?'
    }
  })

DropdownController['$inject'] = ['$element', '$scope', 'navbarCurrent']
function DropdownController ($element, $scope, navbarCurrent) {
  const ctrl = this

  ctrl.$postLink = test
  ctrl.flag      = flag

  $scope.$on('$locationChangeSuccess', test)

  // Functions
  // -------------------------------------------------------------------------
  function test () {
    if (navbarCurrent(ctrl.href)) {
      flag()
    } else {
      $element.removeClass('active')
    }
  }

  function flag () {
    $element.addClass('active')
  }
}
