// Given one template:
//   div( expandable )
//     component-with-isolated-scope
//     component-with-isolated-scope
//
// And the template of the component:
//   div( expandable-relay )
//     div( ng-if="expandable.expanded($ctrl.object)" )
//
// expandable-relay allow access to the parent expandable controller
// in the current scope, even in a isolated scope.
//
angular
  .module('atelier.expandable')
  .directive('expandableRelay', directive)

directive['$inject'] = []
function directive () {
  return {
    restrict: 'A',
    require:  '^^expandable',
    link:     function (scope, _elm, _att, expandable) {
      scope.expandable = expandable
    }
  }
}
