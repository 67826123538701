angular
  .module('atelier.loadingButtons')
  .component('submitButton', {
    transclude: true,
    template:
      '<md-button class="md-primary" type="submit" ng-disabled="$ctrl.disabled || ($ctrl.enabledWhenValid && !$ctrl.formCtrl.$valid)">' +
        '<md-progress-circular md-diameter="16" ng-if="$ctrl.formCtrl.$submitted"></md-progress-circular>' +
        '<ng-transclude></ng-transclude>' +
      '</md-button>',
    require: {
      formCtrl: '^?form'
    },
    bindings: {
      disabled:         '@',
      enabledWhenValid: '<'
    }
  })
