require('./leaflet/L.DomEvent')
require('./leaflet/L.Control.DrawSession')
require('./leaflet/L.ContextMenu')
require('./leaflet/L.Control.Feature')
require('./leaflet/L.Control.Sidebar')
require('./leaflet/L.Control.ZoomBar')
require('./leaflet/L.OneBox')
require('./leaflet/L.PolygonsSelection')
require('./leaflet/L.GeoJSON')
require('./leaflet/L.GeoJSON.HTTP')
require('./leaflet/L.GeoJSON.HTTP.Sync')

angular
  .module('atelier.GIS')
  .component('leaflet', {
    controller: LeafletController,
    bindings:   {
      service: '=gis'
    }
  })

LeafletController['$inject'] = ['GIS', 'LeafletModules', 'handleGesture', '$element', '$scope', '$timeout']
function LeafletController (GIS, LeafletModules, handleGesture, $element, $scope, $timeout) {
  const ctrl = this
  let service, options, map

  // Hooks
  ctrl.$onInit    = onInitWithTimeout
  ctrl.$onDestroy = onDestroy

  // Properties
  ctrl.modules    = []

  // Hooks
  // -------------------------------------------------------------------------
  function onInitWithTimeout () {
    // Let few milliseconds to the browser to completely render the DOM
    // and let the parent container to get it's definitive size.
    $timeout(onInit, 250)
  }

  function onInit () {
    service = ctrl.service

    if (!(service instanceof GIS.Factory)) {
      service = ctrl.service = new GIS.Factory(service)
    }

    options = service.options
    map     = ctrl.map = L.map($element[0], options.leafletMap)

    // Controls
    // ----------------------------------------
    ctrl.sidebarControl = L.control.sidebar(options.sidebarControl)
    ctrl.zoomBarControl = L.control.zoomBar(angular.merge({ reset: resetZoom }, options.zoomBarControl))
    ctrl.featureControl = L.control.feature(options.featureControl)

    // Other extensions
    // ----------------------------------------
    ctrl.oneBox            = L.oneBox(map, options.oneBox)
    ctrl.polygonsSelection = L.polygonsSelection(map, options.polygonsSelection)
    ctrl.contextMenu       = L.contextMenu(map, options.contextMenu)

    // Modules
    // ----------------------------------------
    ctrl.basemaps          = addModule(LeafletModules.Basemaps)
    ctrl.overlays          = addModule(LeafletModules.Overlays)
    ctrl.markers           = addModule(LeafletModules.Markers)
    ctrl.currentMarker     = addModule(LeafletModules.CurrentMarker)
    ctrl.bounds            = addModule(LeafletModules.Bounds)
    ctrl.selection         = addModule(LeafletModules.Selection)
    ctrl.pegman            = addModule(LeafletModules.Pegman)
    ctrl.photo             = addModule(LeafletModules.Photo)

    service.on('redraw', redraw)
    service.on('request:showControls', showControls)
    service.on('request:hideControls', hideControls)
    service.on('request:openOneBox', openOneBox)
    service.on('streetViewVisibleChanged', onStreetViewVisibilityChanged)

    map.on('onebox.show', onOneBoxOpened)

    // Activate controls, contextMenu & OneBox after every properties have
    // been instantiated (controls, extensions & modules)
    //
    if (options.showControls) showControls()
    if (options.contextMenu) ctrl.contextMenu.enable()
    if (options.openOneBox) openOneBox(options.openOneBox)

    if (options.handleGesture) {
      handleGesture.add($element, enableScrollWheel, disableScrollWheel)
    }

    ctrl.bounds.clearMapView()
    redraw()

    $scope.$watch(getElementSize, resize)
  }

  function onDestroy () {
    ctrl.modules.forEach(function (mod) {
      if (angular.isFunction(mod.$onDestroy)) mod.$onDestroy()
    })

    if (service) {
      service.off('redraw', redraw)
      service.off('request:showControls', showControls)
      service.off('request:hideControls', hideControls)
      service.off('request:openOneBox', openOneBox)
      service.off('streetViewVisibleChanged', onStreetViewVisibilityChanged)
    }

    handleGesture.remove($element)

    if (map) map.remove()
  }

  // Private functions
  // -------------------------------------------------------------------------
  function addModule (ModuleClass) {
    const module = new ModuleClass()

    module.$leaflet = ctrl
    module.$service = service

    if (angular.isFunction(module.$onInit)) module.$onInit()

    ctrl.modules.push(module)
    return module
  }

  // Redrawing stuff
  // -------------------------------------------------------------------------
  // Force redraw the current map
  // It set a tiny delay to be sure that the DOM animation are completed
  // and all elements have their final size
  function redraw () {
    $timeout(function () {
      resizeNow()
      if (ctrl.bounds) ctrl.bounds.update()
    }, 250)
  }

  function resize () {
    map.invalidateSize()
    $timeout(resizeNow, 250)
  }

  function resizeNow () {
    map.invalidateSize({
      animate: false,
      pan:     false
    })
  }

  function resetZoom () {
    if (ctrl.bounds) ctrl.bounds.reset()
  }

  function getElementSize () {
    return $element[0].offsetWidth + 'x' + $element[0].offsetHeight
  }

  // Hide/show controls
  // -------------------------------------------------------------------------
  function showControls () {
    ctrl.sidebarControl.addTo(map)
    ctrl.zoomBarControl.addTo(map)
    ctrl.featureControl.addTo(map)
  }

  function hideControls () {
    ctrl.sidebarControl.remove()
    ctrl.zoomBarControl.remove()
    ctrl.featureControl.remove()
  }

  // Miscellaneous actions
  // -------------------------------------------------------------------------
  function openOneBox (options) {
    ctrl.oneBox.open(options)
  }

  function disableScrollWheel () {
    map.scrollWheelZoom.disable()
  }

  function enableScrollWheel () {
    map.scrollWheelZoom.enable()
  }

  function onOneBoxOpened () {
    ctrl.contextMenu.close()
  }

  function onStreetViewVisibilityChanged () {
    if (service.get('streetViewVisible')) {
      ctrl.oneBox.close()
      ctrl.selection.disable()
      ctrl.contextMenu.disable()
    } else {
      ctrl.selection.enable()
      if (options.contextMenu) ctrl.contextMenu.enable()
    }
  }
}
