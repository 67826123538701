angular
  .module('atelier.navbar')
  .component('navbarButton', {
    controller: ButtonController,
    template:   require('./button.template.pug')(),
    transclude: true,
    bindings:   {
      href:    '@?',
      ngClick: '@?'
    },
    require: {
      navbarCtrl:   '^navbar',
      dropdownCtrl: '?^^navbarDropdown'
    }
  })

ButtonController['$inject'] = ['$element', '$window', '$scope', '$timeout', 'navbarCurrent']
function ButtonController ($element, $window, $scope, $timeout, navbarCurrent) {
  const ctrl = this

  ctrl.$postLink = test
  ctrl.follow    = follow

  $scope.$on('$locationChangeSuccess', test)

  // Functions
  // -------------------------------------------------------------------------
  function follow (event) {
    if (event.ctrlKey || event.metaKey) return

    event.preventDefault()
    $window.location = ctrl.href
  }

  function test () {
    if (navbarCurrent(ctrl.href)) {
      $element.addClass('active')
      ctrl.navbarCtrl.activeTextMenu = $element[0].textContent

      if (ctrl.dropdownCtrl) {
        $timeout(function () {
          ctrl.dropdownCtrl.flag()
        })
      }
    } else {
      $element.removeClass('active')
    }
  }
}
