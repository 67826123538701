angular
  .module('atelier.resources')
  .directive('sortCollection', function () {
    return {
      restrict:         'A',
      controller:       Controller,
      bindToController: true,
      scope:            {
        collection: '=sortCollection'
      }
    }
  })

Controller['$inject'] = ['ResourceSorting']
function Controller (ResourceSorting) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit = onInit

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    const collection = ctrl.collection

    if (angular.isUndefined(collection.$sort)) {
      collection.$sort   = new ResourceSorting(collection)
      collection.total   = collection.length
      collection.refresh = refresh
    }
  }

  // Private functions
  // ---------------------------------------------------------------------------
  function refresh () {
    const collection  = ctrl.collection
    const items       = ctrl.collection.$sort.filter(collection)

    collection.splice(0, collection.length)
    collection.push.apply(collection, items)
    collection.total = collection.length
  }
}
