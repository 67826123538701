require('./L.Angular')

L.ContextMenu = L.Class.extend({
  initialize:   initialize,
  enable:       enable,
  disable:      disable,
  open:         open,
  close:        close,
  _onTrigger:   _onTrigger,
  _setPosition: _setPosition
})

L.contextMenu = function (map, html) {
  return new L.ContextMenu(map, html)
}

function initialize (map, html) {
  const container = L.DomUtil.create('div', 'leaflet-context-menu', map._container)

  this._map       = map
  this._html      = html
  this._container = container
  this._visible   = false

  L.DomEvent.disableClickPropagation(container)
  L.DomEvent.disableContextMenuPropagation(container)

  this._map
    .on('contextmenu', this._onTrigger, this)
    .on('mousedown', this.close, this)
    .on('movestart', this.close, this)
    .on('zoomstart', this.close, this)
}

function enable () {
  this.enabled = true
}

function disable () {
  this.enabled = false
  this.close()
}

function open (latlng, layer) {
  if (!this.enabled) return

  this._scope = L.Angular.compile(this._container, this._html, {
    $latlng: latlng,
    $layer:  layer
  })

  this._setPosition(latlng)
  this._container.style.display = 'block'
  this._visible = true

  this._map.fire('contextmenu.show', {
    latlng: latlng,
    layer:  layer
  })

  return this
}

function close () {
  if (this._visible) {
    this._visible = false
    this._container.style.display = 'none'

    if (this._scope) this._scope.$destroy()

    this._map.fire('contextmenu.hide')
  }

  return this
}

function _onTrigger (event) {
  // By default, `contextmenu` event are not propagated from
  // underlying layers and `event.propagatedFrom` is undefined.
  //
  // This event can be propagated from layer to map if
  // the following function has been called on layer :
  //
  //    L.DomEvent.allowEventPropagationToMap(layer, 'contextmenu')

  this.open(event.latlng, analyzeLayer(event.propagatedFrom))
}

function analyzeLayer (layer) {
  let type

  if (layer instanceof L.Polygon) {
    type = 'polygon'
  } else if (layer instanceof L.Polygon) {
    type = 'polyline'
  } else if (layer instanceof L.Path) {
    type = 'path'
  } else if (layer instanceof L.Layer) {
    type = 'layer'
  }

  return {
    type:  type,
    layer: layer
  }
}

function _setPosition (latlng) {
  const map       = this._map
  const container = this._container
  const mapSize   = map.getSize()
  const point     = map.latLngToContainerPoint(latlng)

  if (point.x > (mapSize.x / 2)) {
    container.style.left  = 'auto'
    container.style.right = Math.min(Math.max(mapSize.x - point.x, 0), mapSize.x) + 'px'
  } else {
    container.style.left  = point.x + 'px'
    container.style.right = 'auto'
  }

  container.style.top    = point.y + 'px'
  container.style.bottom = 'auto'
}
