angular
  .module('atelier.inputs')
  .directive('rangeHelper', directive)

const FOCUS_TRAP_TEMPLATE = angular.element(
  '<div class="_md-panel-focus-trap" tabindex="0"></div>'
)

directive['$inject'] = ['$mdPanel', '$timeout']
function directive ($mdPanel, $timeout) {
  return {
    restrict: 'A',
    link:     link,
    scope:    {
      model: '=ngModel'
    }
  }

  function link (scope, element) {
    let inputContainer, panel, animation, position, delay,
      bottomInputFocusTrap, topPanelFocusTrap, bottomPanelFocusTrap,
      blurCloseTimeout

    element.on('focus', open)

    function open () {
      if (panel) return

      element.on('blur', inputBlurHandler)

      inputContainer = element.parent('md-input-container')

      animation = $mdPanel.newPanelAnimation()
        .openFrom(element)
        .withAnimation($mdPanel.animation.FADE)

      position = $mdPanel.newPanelPosition()
        .relativeTo(element)
        .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW)
        .addPanelPosition($mdPanel.xPosition.ALIGN_END, $mdPanel.yPosition.BELOW)
        .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.ABOVE)
        .addPanelPosition($mdPanel.xPosition.ALIGN_END, $mdPanel.yPosition.ABOVE)

      panel = $mdPanel.create({
        clickOutsideToClose:      true,
        escapeToClose:            true,
        propagateContainerEvents: false,
        disableParentScroll:      false,
        focusOnOpen:              false,
        bindToController:         true,
        animation:                animation,
        position:                 position,
        attachTo:                 angular.element(document.body),
        onDomAdded:               onDomAdded,
        onDomRemoved:             onDomRemoved,
        controller:               angular.noop,
        controllerAs:             '$panel',
        template:                 '<range-helper-panel input="$panel.element" input-scope="$panel.scope"></range-helper-panel>',
        locals:                   {
          scope:   scope,
          element: element
        }
      })

      delay = calculateOpenDelay()

      $timeout(() => {
        if (panel) panel.open({ position: position })
      }, delay)
    }

    function onDomAdded () {
      inputContainer.addClass('md-input-panel-attached')

      panel.panelEl.on('focus', panelElementFocusHandler)
      panel.panelEl.find('md-select, input').on('focus', panelElementFocusHandler)

      bottomInputFocusTrap = FOCUS_TRAP_TEMPLATE.clone()[0]
      topPanelFocusTrap    = FOCUS_TRAP_TEMPLATE.clone()[0]
      bottomPanelFocusTrap = FOCUS_TRAP_TEMPLATE.clone()[0]

      bottomInputFocusTrap.addEventListener('focus', bottomInputFocusHandler)
      topPanelFocusTrap.addEventListener('focus', topPanelFocusHandler)
      bottomPanelFocusTrap.addEventListener('focus', bottomPanelFocusHandler)

      element[0].parentNode.insertBefore(bottomInputFocusTrap, element[0].nextSibling)
      panel.panelEl[0].parentNode.insertBefore(topPanelFocusTrap, panel.panelEl[0])
      panel.panelEl.after(bottomPanelFocusTrap)
    }

    function onDomRemoved () {
      inputContainer.removeClass('md-input-panel-attached')

      angular.element(bottomInputFocusTrap).remove()
      angular.element(topPanelFocusTrap).remove()
      angular.element(bottomPanelFocusTrap).remove()
      element.off('blur', inputBlurHandler)
      panel = null
    }

    function calculateOpenDelay () {
      if (!inputContainer || inputContainer.hasClass('md-input-focused') || inputContainer.hasClass('md-input-has-value') || angular.isDefined(inputContainer.attr('md-no-float'))) {
        return 0
      } else {
        return 250
      }
    }

    // Event handlers
    // -------------------------------------------------------------------------
    function inputBlurHandler () {
      blurCloseTimeout = $timeout(() => {
        if (panel && panel.isAttached) panel.close()
        panel = null
        element.off('blur', inputBlurHandler)
      })
    }

    function panelElementFocusHandler () {
      $timeout.cancel(blurCloseTimeout)
    }

    function bottomInputFocusHandler () {
      if (panel && panel.isAttached) {
        $timeout.cancel(blurCloseTimeout)
        panel.panelEl.find('md-select, input')[0].focus()
      }
    }

    function topPanelFocusHandler () {
      element.focus()
    }

    function bottomPanelFocusHandler () {
      panel.panelEl[0].focus()
    }
  }
}
