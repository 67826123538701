/**
 * Sets the framing of the map
 */
angular
  .module('atelier.GIS')
  .factory('LeafletModules.Bounds', Bounds)

Bounds['$inject'] = ['$q', '$log']
function Bounds ($q, $log) {
  return function () {
    const mod  = this
    let leaflet
    let service

    // Hooks
    mod.$onInit    = onInit
    mod.$onDestroy = onDestroy

    // Functions
    mod.reset         = resetBounds
    mod.update        = updateBounds
    mod.clearMapView  = clearMapView

    // Hooks
    // -----------------------------------------------------------------------
    function onInit () {
      leaflet = mod.$leaflet
      service = mod.$service

      leaflet.map.on('moveend', saveMapView)
      leaflet.map.on('zoomend', saveMapView)
    }

    function onDestroy () {
      leaflet.map.off('moveend', saveMapView)
      leaflet.map.off('zoomend', saveMapView)
    }

    // Public functions
    // -----------------------------------------------------------------------
    function clearMapView () {
      service.cache.put('mapView', null)
    }

    function resetBounds () {
      clearMapView()
      return resetBoundsOnOverlays()
        .catch(catchInvalidBounds(resetBoundsOnMarkers))
        .catch(catchInvalidBounds(resetDefaultBounds))
        .catch(catchInvalidBounds(resetBoundsOnFrance))
    }

    function updateBounds (markers) {
      markers = markers || service.get('focused') || service.get('markers')

      if (markers && markers.length) {
        return resetBoundsOnMarkers(markers).catch(catchInvalidBounds(resetBounds))
      } else {
        return resetBoundsOnMapView().catch(catchInvalidBounds(resetBounds))
      }
    }

    // Private functions: resets functions
    // -------------------------------------------------------------------------
    function resetBoundsOnMapView () {
      const mapView = service.cache.get('mapView')

      if (mapView && mapView.position && mapView.zoom) {
        return setView(mapView.position, mapView.zoom)
      } else {
        return $q.reject('Invalid bounds')
      }
    }

    function resetBoundsOnFrance () {
      return setView([46.1277732, -2.2867273], 6)
    }

    function resetBoundsOnMarkers (markers) {
      let bounds

      if (angular.isUndefined(markers)) {
        markers = service.get('markers')
        bounds  = leaflet.markers.layer.getBounds()
      } else {
        bounds = L.latLngBounds()
        markers.forEach(function (point) {
          if (angular.isFunction(point.getLatLng)) {
            point = point.getLatLng()
          }
          bounds.extend(point)
        })
      }

      if (!markers || !markers.length) {
        return $q.reject('Invalid bounds')
      } else if (service.options.keepDefaultBoundsOnReset) {
        return service.getDefaultBounds().then(function (value) {
          bounds.extend(value)
          return setBounds(bounds)
        })
      } else if (markers.length === 1) {
        return setView(markers[0])
      } else {
        return setBounds(bounds)
      }
    }

    function resetBoundsOnOverlays () {
      return $q.all([
        service.get('requestedOverlays'),
        service.get('requestedGeoJsonLayer'),
        service.getDefaultBounds()
      ]).then(function (results) {
        const bounds = L.latLngBounds()

        if (results[0]) {
          leaflet.overlays.eachLayers(results[0], function (layer) {
            bounds.extend(layer.getBounds())
          })
        }

        if (results[1]) {
          bounds.extend(L.geoJson(results[1]).getBounds())
        }

        if (service.options.keepDefaultBoundsOnReset) {
          bounds.extend(results[2])
        }

        return setBounds(bounds)
      })
    }

    function resetDefaultBounds () {
      return service.getDefaultBounds().then(function (value) {
        return setBounds(L.latLngBounds(value))
      })
    }

    function saveMapView () {
      service.cache.put('mapView', {
        position: leaflet.map.getCenter(),
        zoom:     leaflet.map.getZoom()
      })
    }

    function setBounds (value) {
      if (value.isValid()) {
        try {
          leaflet.map.fitBounds(value, {
            maxZoom: service.options.resetBoundsWithMaxZoom
          })
        } catch (e) {
          return $q.reject('Invalid bounds')
        }

        return $q.resolve()
      } else {
        return $q.reject('Invalid bounds')
      }
    }

    function setView (point, zoom) {
      if (angular.isFunction(point.getLatLng)) {
        point = point.getLatLng()
      }

      if (!zoom) zoom = service.options.resetBoundsWithMaxZoom

      try {
        leaflet.map.setView(point, zoom)
      } catch (e) {
        return $q.reject('Invalid bounds')
      }

      return $q.resolve()
    }

    function catchInvalidBounds (nextPromise) {
      return function (reason) {
        if (reason === 'Invalid bounds') {
          return nextPromise()
        } else {
          return $q.reject(reason)
        }
      }
    }
  }
}
