export default {
  tableau: function (factory) {
    return factory.injectUrl(
      'https://tableau.solutions-territoire.fr/javascripts/api/tableau_v8.js',
      () => window.tableauSoftware
    )
  },
  screenfull: function () {
    return import(/* webpackChunkName: "screenfull" */ 'screenfull')
  },
  googleMaps: function (factory) {
    return factory.injectUrl(
      'https://maps.googleapis.com/maps/api/js?v=3&key=' + process.env.GOOGLE_MAPS_API_KEY + '&callback=angular.noop',
      () => window.google.maps
    ).then(() => {
      return import(/* webpackChunkName: "googlemutant" */ 'leaflet.gridlayer.googlemutant')
    }).then(() => {
      return window.google.maps
    })
  },
  esri: function () {
    return import(/* webpackChunkName: "esri-leaflet" */ 'esri-leaflet')
  },
  oalley: function () {
    return import(/* webpackChunkName: "oalley" */ 'oalley.js').then(({ default: Oalley }) => {
      return Oalley
    })
  },
  jsts: function () {
    return import(/* webpackChunkName: "jsts" */ 'jsts')
  },
  proj4leaflet: function () {
    return import(/* webpackChunkName: "proj4leaflet" */ 'proj4leaflet')
  }
}
