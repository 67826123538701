angular
  .module('atelier.labelize')
  .provider('labelize', labelizeProvider)

labelizeProvider['$inject'] = ['objectPath']
function labelizeProvider (objectPath) {
  const labels = {}

  // Bindable factory
  // ---------------------------------------------------------------------------
  this.$get = labelizeFactory

  // Bindable provider members
  // ---------------------------------------------------------------------------
  this.add    = add
  this.set    = set
  this.extend = extend

  // Public provider functions
  // ---------------------------------------------------------------------------
  function get (path) {
    return objectPath.get(labels, path)
  }

  function set (path, value) {
    objectPath.set(labels, path, value)
    return this
  }

  function add (key, value) {
    labels[key] = value
    return this
  }

  function extend (value) {
    angular.extend(labels, value)
    return this
  }

  // Factory definition
  // ---------------------------------------------------------------------------
  // labelizeFactory['$inject] = []
  function labelizeFactory () {
    return get
  }
}
