angular
  .module('atelier.resources')
  .directive('sortBy', function () {
    return {
      restrict:         'A',
      controller:       angular.noop,
      controllerAs:     '$ctrl',
      bindToController: true,
      transclude:       true,
      link:             link,
      template:         require('./sort_by.template.pug')(),
      require:          {
        collectionCtrl: '^^sortCollection'
      },
      scope: {
        sortBy: '@sortBy'
      }
    }
  })

function link (scope, element) {
  scope.ariaLabel = element.text()
}
