angular
  .module('atelier.GIS')
  .factory('handleGesture', handleGesture)

handleGesture['$inject'] = ['$timeout']
function handleGesture ($timeout) {
  return {
    add:    add,
    remove: remove
  }

  // TODO: hanle mobile gesture like Lealfet.GestureHandling

  // Public functions
  // ------------------------------------------------------------
  function add (element, enableScrollWheel, disableScrollWheel) {
    remove(element)

    element[0].gestureHandling = {
      disableScrollWheel: disableScrollWheel,
      enableScrollWheel:  enableScrollWheel
    }

    element.on('mouseover', enableInteractions)
    element.on('mouseenter', enableInteractions)
    element.on('mouseleave', disabledInteractions)
  }

  function remove (element) {
    element.off('mouseover', enableInteractions)
    element.off('mouseenter', enableInteractions)
    element.off('mouseleave', disabledInteractions)
  }

  // Private functions
  // ------------------------------------------------------------

  function enableInteractions () {
    const handler = this.gestureHandling
    if (handler.timer) $timeout.cancel(handler.timer)

    handler.timer = $timeout(handler.enableScrollWheel, 500)
  }

  function disabledInteractions () {
    const handler = this.gestureHandling
    if (handler.timer) $timeout.cancel(handler.timer)

    handler.disableScrollWheel()
  }
}
