/**
 * Handles the display of the current marker on the leaflet map
 */
angular
  .module('atelier.GIS')
  .factory('LeafletModules.CurrentMarker', CurrentMarker)

// CurrentMarker['$inject'] = []
function CurrentMarker () {
  return function () {
    const mod  = this
    let leaflet, service, icon, marker, selectedMarker

    // Hooks
    mod.$onInit     = onInit
    mod.$onDestroy  = onDestroy

    // Functions
    mod.show = show
    mod.hide = hide

    // Hooks
    // -----------------------------------------------------------------------
    function onInit () {
      leaflet = mod.$leaflet
      service = mod.$service

      icon    = L.divIcon(service.options.currentIcon)
      marker  = L.marker(null, {
        icon:         icon,
        zIndexOffset: service.options.markersIndex.current
      })

      mod.marker = marker

      leaflet.map.on('contextmenu.show', onContextMenuShow)
      leaflet.map.on('contextmenu.hide', onContextMenuHide)
    }

    function onDestroy () {
      leaflet.map.off('contextmenu.show', onContextMenuShow)
      leaflet.map.off('contextmenu.hide', onContextMenuHide)
    }

    // Private function
    // -----------------------------------------------------------------------
    function show (latlng) {
      marker.setLatLng(latlng).addTo(leaflet.map)
    }

    function hide () {
      marker.remove()
    }

    // Private function
    // -----------------------------------------------------------------------
    function onContextMenuShow (event) {
      if (event.layer.type === 'marker') {
        selectedMarker = event.layer.layer
        selectedMarker.focus()
      } else {
        show(event.latlng)
      }
    }

    function onContextMenuHide (_event) {
      if (selectedMarker) {
        selectedMarker.blur()
        selectedMarker = null
      } else {
        hide()
      }
    }
  }
}
