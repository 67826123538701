/**
 * Uses oallay to display informations about travel time
 */
angular
  .module('atelier.GIS')
  .component('oalley', {
    controller: Controller,
    template:   require('./oalley.template.pug')(),
    bindings:   {
      latlng:   '<',
      type:     '@',
      onSearch: '&?'
    },
    require: {
      leafletCtrl: '^^leaflet'
    }
  })

Controller['$inject'] = ['thirdParty', '$log']
function Controller (thirdParty, $log) {
  const ctrl = this
  let oalleyApi

  // Bindable members
  // -------------------------------------------------------------------------
  ctrl.$onInit    = onInit
  ctrl.$onDestroy = onDestroy
  ctrl.submit     = submit

  ctrl.input = 10
  ctrl.mode  = 'car'
  ctrl.modes = {
    car:  'En voiture',
    bike: 'En vélo',
    walk: 'A pied'
  }

  // Component hooks
  // -------------------------------------------------------------------------
  function onInit () {
    const map  = ctrl.leafletCtrl.map
    const pane = map.getPane('oalleyPane') || map.createPane('oalleyPane')

    pane.style.zIndex = 500

    ctrl.leafletCtrl.currentMarker.show(ctrl.latlng)

    thirdParty.inject('oalley').then(function (Oalley) {
      oalleyApi = new Oalley(process.env.OALLEY_API_KEY)
    })
  }

  function onDestroy () {
    if (ctrl.polygon) ctrl.polygon.remove()

    ctrl.leafletCtrl.currentMarker.hide()
  }

  // Public functions
  // -------------------------------------------------------------------------
  function submit () {
    if (ctrl.polygon) ctrl.polygon.remove()

    const data = {
      mode: ctrl.mode,
      lng:  ctrl.latlng.lng,
      lat:  ctrl.latlng.lat
    }

    if (ctrl.type === 'isochrone') {
      data.duration = ctrl.input * 60
    } else {
      data.distance = ctrl.input * 1000
    }

    oalleyApi[ctrl.type](data, function (error, data) {
      if (error) {
        $log.error(error)
        return
      }

      ctrl.form.$submitted = false

      ctrl.polygon = L.polygon(data[0], { pane: 'oalleyPane', color: 'purple', opacity: 0.6 })
      ctrl.polygon.addTo(ctrl.leafletCtrl.map)

      ctrl.leafletCtrl.map.fitBounds(ctrl.polygon.getBounds())
    })
  }
}
