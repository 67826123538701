angular
  .module('atelier.numericBadge')
  .component('numericBadge', {
    bindings: {
      count: '='
    },
    template:
      '<div class="badge-count" ' +
        'ng-class="{ zero: !$ctrl.count }"' +
      '>{{ ($ctrl.count > 99 ? \'99+\' : $ctrl.count) || 0 }}</div>'
  })
